import React, { useState } from "react";
import { toast } from "react-toastify";

const Step1 = ({ onNextStep }) => {
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [occupation, setOccupation] = useState("");
  const [fullName, setFullName] = useState("");
  const [residence, setResidence] = useState("");
  const [localGovt, setLocalGovt] = useState("");

  const [mobile, setMobileNumber] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value.replace(/\s/g, ""));
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value.replace(/\s/g, ""));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.replace(/\s/g, ""));
  };

  const confirmhandlePasswordChange = (e) => {
    setconfirmPassword(e.target.value.replace(/\s/g, ""));
  };

  const handleOccupationChange = (e) => {
    setOccupation(e.target.value);
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleResidenceChange = (e) => {
    setResidence(e.target.value);
  };

  const handleLocalGovtChange = (e) => {
    setLocalGovt(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value.replace(/\s/g, ""));
  };

  const validateFields = () => {
    // Simple validation checks
    if (
      email.length < 3 ||
      gender.length < 3 ||
      password.length < 3 ||
      confirmpassword.length < 3 ||
      occupation.length < 3 ||
      fullName.length < 3 || // Add this line for the "Full name" input
      residence.length < 3 ||
      localGovt.length < 3 ||
      mobile.length < 3
    ) {
      toast.error("Please fill out all fields with at least 3 characters.");
      return false;
    }

    if (password !== confirmpassword) {
      toast.error("Passwords do not match.");
      return false;
    }

    return true;
  };

  const handleContinue = () => {
    if (validateFields()) {
      // If validation passes, move to the next step
      onNextStep();
    }
  };

  return (
    <div className=" w-full md:w-[700px]">
      <div className=" grid grid-cols-2 gap-3 shadow-sm mb-4 mx-2">
        <div className=" bg-[#0D6EFD] py-[3px] rounded-xl"></div>
        <div className=" bg-[#a1c4f7] py-[3px] rounded-xl"></div>
      </div>
      <div className=" w-[1/3] px-10 py-5 shadow-2xl">
        <h1 className=" mb-5 font-bold">Sign Up</h1>
        <div className=" w-full">
          <h1 className=" font-bold text-gray-600 mb-1">Full name</h1>
          <input
            type="text"
            className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
            placeholder="sample@gmail.com"
            name=""
            id=""
            value={fullName}
            onChange={handleFullNameChange}
          />
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-8 my-5">
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1">Email</h1>
            <input
              type="text"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="sample@gmail.com"
              name=""
              id=""
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1">Occupation</h1>
            <input
              type="text"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="sample@gmail.com"
              name=""
              id=""
              value={occupation}
              onChange={handleOccupationChange}
            />
          </div>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-8 my-5">
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1">
              Area of Residence/ address
            </h1>
            <input
              type="text"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="sample@gmail.com"
              name=""
              id=""
              value={residence}
              onChange={handleResidenceChange}
            />
          </div>
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1">
              {" "}
              Local govt. of Residence
            </h1>
            <input
              type="text"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="sample@gmail.com"
              name=""
              value={localGovt}
              id=""
              onChange={handleLocalGovtChange}
            />
          </div>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-8 my-5">
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1"> Mobile Number</h1>
            <input
              type="text"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="+123456789"
              name=""
              value={mobile}
              id=""
              maxLength={11} // Use 'minLength' here
              onChange={handleMobileNumberChange}
            />
          </div>
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1"> Gender</h1>
            <input
              type="text"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              minLength={3}
              placeholder="sample@gmail.com"
              name=""
              id=""
              value={gender}
              onChange={handleGenderChange}
            />
          </div>
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-8 my-5">
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1"> Password</h1>
            <input
              type="password"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="************"
              name=""
              id=""
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className=" w-full">
            <h1 className=" font-bold text-gray-600 mb-1">Confirm Password</h1>
            <input
              type="password"
              className=" w-full py-2 outline-none border border-gray-300 px-2 rounded-md"
              placeholder="************"
              name=""
              id=""
              value={confirmpassword}
              onChange={confirmhandlePasswordChange}
            />
          </div>
        </div>

        <div
          onClick={handleContinue}
          type="submit"
          className=" text-white shadow-md flex justify-center rounded-md bg-[#0D6EFD] font-bold cursor-pointer py-2 w-full"
        >
          <h1>Continue</h1>
        </div>
      </div>
    </div>
  );
};

export default Step1;
