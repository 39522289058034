import { useLocation, Navigate, Outlet } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

const RequireSpecialAuth = () => {
  const { auth, adminRoles } = useAuth();
  const location = useLocation();


  return adminRoles?.includes(auth?.roles) ? (
    <Outlet />
  ) : auth.email ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireSpecialAuth;
