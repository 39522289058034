import React from 'react'
import ResetPwd from '../components/ResetPwd'

function ResetPassword() {
  return (
    <main>
        <ResetPwd/>
    </main>
  )
}

export default ResetPassword