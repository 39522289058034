import React from 'react'
import ManageOffences from '../components/AdminDashBoard/manageOffences'

function OffencesPage() {
  return (
    <div className='min-h-[92vh] text-[16px]'>
      <ManageOffences/>
    </div>
  )
}

export default OffencesPage