import apiClient from "../../api/axios";

export const addUnits = async (data) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  return await apiClient.post(`/api/v1/Units/add`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
