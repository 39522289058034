import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import moment from "moment";
import { UpdateSM } from "../../../utils/profile/updateSM";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { getMarshalById } from "../../../utils/marshals/getMarshalById";
import useAuth from "../../../hooks/useAuth";

const Section2 = ({ State1, State2, det }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(State1?.FirstName);
  const [lastName, setLastName] = useState(State1?.LastName);
  const [profession, setProfession] = useState(State1?.Profession);
  const [dob, setDob] = useState(moment(State1?.Dob).format("YYYY-MM-DD"));
  const [emailAddress, setEmailAddress] = useState(State1?.EmailAddress);
  const [phoneNumber, setPhoneNumber] = useState(State1?.PhoneNumber);
  const [sex, setSex] = useState(State1?.sex);
  const [unitId, setUnitId] = useState(State1?.UnitId);
  const [isLoading, setIsLoading] = useState(false);
  const { units } = useAuth();

  const handleUpdate = async () => {
    try {
      const id = State1?.id;
      const userType = State1?.UserRole;
      setIsLoading(true);

      await UpdateSM({
        id,
        userType,
        firstName,
        lastName,
        profession,
        doB: dob,
        emailAddress,
        phoneNumber,
        sex,
        unitId,
        // Add other fields as needed
      });
      toast.success("Updated Successfully");
      getMarshalById(id).then((res) => {
        setIsEditing(false);
        State2(res?.data?.data);
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error("Failed to update. Please try again.");
    }
  };

  console.log(State1);

  return (
    <div>
      <div className="border w-full border-slate-300 rounded-lg mt-5 px-5 py-5">
        <div className="flex items-center justify-between">
          <h1 className="font-bold mb-3">Personal Information</h1>
          {isEditing ? (
            <div className="flex items-center gap-1">
              <button
                className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>

              <button
                className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
                onClick={handleUpdate}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader type="TailSpin" color="blue" height={18} width={18} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          ) : (
            <button
              className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
              onClick={() => setIsEditing(true)}
            >
              <h1 className=" hidden md:block">Edit</h1>
              <FiEdit />
            </button>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5">
          <div className="w-[200px]">
            <h1 className="font-medium">Name</h1>
            {isEditing ? (
              <div>
                <input
                  type="text"
                  value={firstName || State1?.firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="border-b w-fit outline-none"
                />
                <input
                  type="text"
                  value={lastName || State1?.lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="border-b w-fit outline-none"
                />
              </div>
            ) : (
              <h2 className="font-medium text-slate-500">
                {State1?.firstName} {State1?.lastName}
              </h2>
            )}
          </div>
          <div>
            <h1 className="font-bold">Email Address</h1>
            {isEditing ? (
              <input
                type="email"
                value={emailAddress || State1?.emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="border-b w-fit outline-none"
              />
            ) : (
              <p className="font-medium text-slate-500">
                {State1?.emailAddress}
              </p>
            )}
          </div>
          <div>
            <h1 className="font-bold">Unit</h1>
            {isEditing ? (
              <select
                className="border-b w-fit outline-none"
                value={unitId}
                onChange={(e) => setUnitId(e.target.value)}
              >
                {units?.map((unit) => {
                  return (
                    <option key={unit?.id} value={unit?.id}>
                      {unit?.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <p className="font-medium text-slate-500">{State1?.Unit?.name}</p>
            )}
          </div>
          <div className="w-[200px]">
            <h1 className="font-medium">Occupation</h1>
            {isEditing ? (
              <input
                type="text"
                value={profession || State1?.profession}
                onChange={(e) => setProfession(e.target.value)}
                className="border-b w-fit outline-none"
              />
            ) : (
              <h2 className="font-medium text-slate-500">
                {State1?.profession}
              </h2>
            )}
          </div>
          <div>
            <h1 className="font-bold">Phone Number</h1>
            {isEditing ? (
              <input
                type="tel"
                value={phoneNumber || State1?.PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="border-b w-fit outline-none"
              />
            ) : (
              <p className="font-medium text-slate-500">
                {State1?.phoneNumber}
              </p>
            )}
          </div>
          <div>
            <h1 className="font-bold">Date of birth</h1>
            {isEditing ? (
              <input
                type="date"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                className="border-b w-fit outline-none"
              />
            ) : (
              <p className="font-medium text-slate-500">
                {moment(dob).format("DD MMM, YYYY")}
              </p>
            )}
          </div>
          <div>
            <h1 className="font-bold">Gender</h1>
            {isEditing ? (
              <select
                className="border-b w-fit outline-none"
                value={sex}
                onChange={(e) => setSex(e.target.value)}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            ) : (
              <p className="font-medium text-slate-500">{State1?.sex}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
