import React, { useState } from 'react'
import { HiOutlineDotsVertical } from "react-icons/hi";
import AWS from "aws-sdk";
import useAuth from '../../hooks/useAuth';
import { MdClose } from 'react-icons/md';
import ConfirmationModal from '../common/ConfirmationModal';
import { deleteDocument } from '../../utils/manuals/deleteDoc';
import { getMyDocuments } from '../../utils/documents/getMyDocuments';
import ModalContainer from '../common/ModalContainer';
import { toast } from 'react-toastify';

function DocumentCardView({ docImage, docName, docId, selectedId, setSelectedId, docFileName, imagePath, showDots }) {
    const [showMenu, setShowMenu] = useState(false)
    const [openFullScreen, setOpenFullScreen] = useState(false)
    const [showConfirmationBox, setShowConfirmationBox] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    const {
        REACT_APP_AWS_KEY,
        REACT_APP_AWS_SECRET_KEY,
        REACT_APP_AWS_BUCKET_NAME, setMyDocuments } = useAuth()
    AWS.config.update({
        accessKeyId: REACT_APP_AWS_KEY,
        secretAccessKey: REACT_APP_AWS_SECRET_KEY,
    });
    function downloadImageOnClick() {
        var s3 = new AWS.S3();
        s3.getObject(
            { Bucket: REACT_APP_AWS_BUCKET_NAME, Key: docFileName },
            function (error, data) {
                if (error != null) {
                    console.log("Failed to retrieve an object: " + error);
                } else {
                    let blob = new Blob([data.Body]);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = URL.createObjectURL(blob);
                    downloadLink.download = docFileName;

                    // Trigger the download by simulating a click on the <a> element
                    downloadLink.click();

                }
                setShowMenu(false)
            }
        );
    }

    const handleDeleteDocument = () => {
        setLoading(true);
        deleteDocument(docId).then(() => {
            setLoading(false);
            toast.success("Document deleted successfully")
            getMyDocuments().then((res) => {
                setMyDocuments(res?.data?.data)
            })
        })

    }
    return (
        <div className='bg-white w-[146px] h-[175px] flex flex-col justify-start'>
            {showDots &&
                <span className='flex justify-end p-2 relative'>
                    <HiOutlineDotsVertical size={16} color='black' className='cursor-pointer' onClick={() => { setSelectedId(docId); setShowMenu(!showMenu) }} />
                    {showMenu && docId === selectedId &&
                        <div className='w-[80%] space-y-2 rounded-md bg-white bg-opacity-100 absolute top-8 shadow-xl'>
                            <p className='cursor-pointer p-1 hover:bg-slate-200' onClick={downloadImageOnClick}>Download</p>
                            <p className='cursor-pointer p-1 hover:bg-slate-200' onClick={() => {
                                setDeleteId(docId);
                                setShowConfirmationBox(true)
                            }}>Delete</p>
                        </div>
                    }
                </span>
            }
            <div className={`flex flex-col items-center justify-center gap-4 text-center cursor-pointer ${!showDots && "mt-8"}`} onClick={() => {
                setOpenFullScreen(true)
                setSelectedId(docId)
            }}>
                <img src={docImage} alt="" className='w-[74px] h-[74px] object-cover' />
                <p className='w-[100px] break-all'>{docName}</p>

            </div>
            {openFullScreen && docId === selectedId &&
                <div className='fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-[100] '>
                    <MdClose
                        color="white"
                        size={30}
                        onClick={() => setOpenFullScreen(false)}
                        className='fixed top-8 right-4 cursor-pointer z-[100]'
                    />
                    <img src={imagePath} />
                </div>
            }
            {showConfirmationBox &&
                <ModalContainer height="auto">
                    <ConfirmationModal message="Are you sure you want to delete this document?" handleCancel={() => setShowConfirmationBox(false)} loading={loading} handleConfirm={handleDeleteDocument} />
                </ModalContainer>
            }
        </div>
    )
}

export default DocumentCardView