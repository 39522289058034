import React from "react";
import userImage from "../../img/user.webp";
import { FaRegBell } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { IoMdClose, } from "react-icons/io"
import { IoMdMenu } from "react-icons/io";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

function DashboardNavbar({ openSidebar, setOpenSidebar }) {
  const { handleLogout, auth } = useAuth();
  console.log(auth?.userDetails?.ProfilePictureUrl)
  return (
    <div className=" w-full flex items-center justify-between lg:justify-end">
      <div className="block lg:hidden">
        {openSidebar ? <IoMdClose size={30} onClick={() => setOpenSidebar(false)} /> :
          <IoMdMenu size={30} onClick={() => setOpenSidebar(true)} />
        }
      </div>
      <div className="flex items-center gap-[20px]">
        <p className="text-black text-[10px] md:text-[15px] capitalize">Welcome <span className=" ">{auth?.roles}</span></p>
        <div className="w-[50px] h-[50px] rounded-full">
          <Link to="/dashboard/profile">
            <img src={auth?.userDetails?.profilePictureUrl !== "" ? auth?.userDetails?.profilePictureUrl : userImage} alt="" className="w-[50px] h-[50px] rounded-full object-cover" />
          </Link>
        </div>
        {/* <FaRegBell size={20} /> */}
        <IoLogOutOutline size={20} onClick={handleLogout} className="cursor-pointer" />
      </div>
    </div>
  );
}

export default DashboardNavbar;
