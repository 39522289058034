import React, { useEffect, useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi'
import { getUnits } from '../../utils/units/getUnits';
import useAuth from '../../hooks/useAuth';
import MarshalDocumentCardView from './MarshalDocumentCardView';
import SingleMarshalDocumentView from './SingleMarshalDocumentView';
import { getDocumentTypes } from '../../utils/documents/getDocumentTypes';
import { getAllDocuments } from '../../utils/documents/getAllDocuments';
import { getAllMarshals } from '../../utils/marshals/getAllMarshal';
import Loader from '../common/Loader';

function MarshalDocuments() {
    const { units, setUnits, setDocType, allDocuments, setAllDocuments, marshall, setMarshall, auth, filterMarshalByUnitId } = useAuth()
    const [selectedUnit, setSelectedUnit] = useState("");
    const [openSingleMareshalView, setOpenSingleMarshalView] = useState(false)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(14); // Default number of items per page
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedUser, setSelectedUser] = useState(null)

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = marshall?.filter((u) => searchTerm !== "" ? u?.firstName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || u?.lastName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) : u)?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    useEffect(() => {
        getDocumentTypes().then((res) => {
            setDocType(res?.data)
        })
        getUnits().then((res) => {
            setUnits(res.data?.data)
        });
        if (auth?.roles === "ADMINISTRATOR") {
            getAllDocuments().then((res) => {
                setLoading(false)
                setAllDocuments(res?.data?.data)
            })
        }
        if (marshall?.length < 1) {
            if (auth?.roles === "ADMINISTRATOR") {
                getAllMarshals().then((res) => {
                    setMarshall(res?.data?.data)
                    setLoading(false)
                })
            } else {
                filterMarshalByUnitId(auth?.userDetails?.UnitId)
                setLoading(false)
            }
        }
    }, []);

    return (
        <div className="min-h-[92vh]">
            {!openSingleMareshalView ?
                <>
                    <div className='flex flex-col lg:flex-row items-center gap-4 mb-[28px]'>
                        <div className="relative">
                            <input type="text" placeholder="search" className="relative w-[327px] h-[45px] px-4 pl-8 rounded-[8px] border border-[#D9D9D9] outline-none" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            <HiOutlineSearch className="absolute top-[13px] left-[10px]" size={20} />
                        </div>
                        {auth?.roles === "ADMINISTRATOR" &&
                            <select
                                className="rounded-[8px] border h-[45px] px-4 w-[277px] border-[#A6A6A6] outline-none"
                                value={selectedUnit}
                            // onChange={(e) => filterMarshalByUnit(e.target.value)}
                            >
                                <option value="" selected>
                                    Select Unit
                                </option>
                                {units?.map((unit) => (
                                    <option key={unit?.id} value={unit?.id}>
                                        {unit?.name}
                                    </option>
                                ))}
                            </select>
                        }
                    </div>
                    {loading && <div className="w-full h-[200px] bg-white flex justify-center items-center"><Loader color="black" /></div>}
                    <div className='grid grid-cols-2 lg:grid-cols-7 gap-[12px]'>
                        {currentItems?.map((user) => {
                            return (
                                <MarshalDocumentCardView setOpenSingleMarshalView={setOpenSingleMarshalView} user={user} setSelectedUser={setSelectedUser} />
                            )
                        })}
                    </div>
                    {marshall?.length > 0 && (
                        <div className="mt-4 flex justify-between items-center">
                            <div className="flex items-center gap-2">
                                <label className=" text-[13px] md:text-[15px]">Items Per Page</label>
                                <select
                                    className="bg-white p-1 md:p-2 rounded-md text-[13px]"
                                    value={itemsPerPage}
                                    onChange={(e) => setItemsPerPage(e.target.value)}
                                >
                                    <option value="14">14</option>
                                    <option value="28">28</option>
                                    <option value="56">56</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </div>
                            {marshall?.length > itemsPerPage && (
                                <div>
                                    <div className="flex gap-4">
                                        {[...Array(Math.ceil(marshall.length / itemsPerPage))].map(
                                            (_, index) => {
                                                // Calculate the range of pages to display
                                                const startPage = currentPage <= 3 ? 0 : currentPage - 3;
                                                const endPage =
                                                    startPage + 5 >= Math.ceil(marshall.length / itemsPerPage)
                                                        ? Math.ceil(marshall.length / itemsPerPage)
                                                        : startPage + 5;

                                                // Render pagination buttons within the range
                                                if (index >= startPage && index < endPage) {
                                                    return (
                                                        <button
                                                            key={index}
                                                            onClick={() => paginate(index + 1)}
                                                            className={`${currentPage === index + 1
                                                                ? "bg-blue-500 text-white p-2 px-4 rounded-md"
                                                                : ""
                                                                } text-[15px] cursor-pointer`}
                                                        >
                                                            {index + 1}
                                                        </button>
                                                    );
                                                }
                                                return null;
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </> : <SingleMarshalDocumentView goBack={setOpenSingleMarshalView} selectedUser={selectedUser} />
            }
        </div>
    )
}

export default MarshalDocuments