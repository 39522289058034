
import apiClient from "../../api/axios";

export const updateUnit = async (data) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  return await apiClient.put(`/api/v1/Units/update`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
