import axios from "axios";
import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import Table from "../common/Table";
import { RxCross2 } from "react-icons/rx";
import { IoMdCheckmark } from "react-icons/io";
import { HiOutlineSearch } from "react-icons/hi";
import ModalContainer from "../common/ModalContainer";
import AddNewReport from "../Forms.js/AddNewReport";
import { getMyReports } from "../../utils/reports/getMyReport";
import ConfirmationModal from "../common/ConfirmationModal";
import { deleteReport } from "../../utils/reports/deleteReport";
import { toast } from "react-toastify";

function AdminReports() {
  const { myReports, setMyReports } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    getMyReports()
      .then((res) => {
        setLoading(false)
        setMyReports(res?.data?.data);
      })
      .catch(() => {
        console.log("Error fetching report");
      });
  }, [modalIsOpen]);

  const handleReportDelete = () => {
    setLoading(true);
    deleteReport(rowData?.Id).then((res) => {
      setLoading(false);
      toast.success("Report deleted successfully")
      getMyReports()
        .then((res) => {
          setMyReports(res?.data?.data);
        })
        .catch(() => {
          console.log("Error fetching report");
          setLoading(false);
        });
      setOpenModal({ modalName: "", showModal: false });
    });
  };
  return (
    <div className="min-h-[92vh] mt-4">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-[30px]">
        <div className="relative mb-4 lg:mb-0">
          <input
            className="w-[343px] h-[50px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="absolute top-[16px] left-[15px] text-[20px]">
            <HiOutlineSearch />
          </span>
        </div>
        <button
          className="rounded-[8px] bg-[#0D6EFD] text-[16px] text-white px-3 py-[10px] flex items-center gap-2"
          onClick={() => setModalIsOpen(true)}
        >
          Add a New Report <span className="text-[28px] font-semibold">+</span>
        </button>
      </div>
      <Table
      state={loading}
        header={[
          { name: "Report ID", identifier: "id" },
          { name: "Report Title", identifier: "title" },
          { name: "Location", identifier: "location" },
          { name: "Incident", identifier: "incident" },
          // { name: "Date", identifier: "phoneNumber" },
        ]}
        data={[...myReports]}
        options={{
          variant: "primary",
          tableStyle: "",
          allowSorting: false,
          allowActions: true,
          actions: {
            edit: true,
            view: true,
            delete: true,
          },
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        searchQuery={searchTerm}
        
      />
      {modalIsOpen && (
        <ModalContainer>
          <AddNewReport closeModal={setModalIsOpen} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "edit" && (
        <ModalContainer>
          <AddNewReport
            mode="edit"
            closeModal={setOpenModal}
            rowData={rowData}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "view" && (
        <ModalContainer>
          <AddNewReport
            mode="view"
            closeModal={setOpenModal}
            rowData={rowData}
          />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "delete" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to delete this report?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={handleReportDelete}
            loading={loading}
          />
        </ModalContainer>
      )}
    </div>
  );
}

export default AdminReports;
