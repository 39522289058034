import React from "react";
import logo from "../../img/frsc-logo.png";
import { AdminSidebarLinks, UsersSidebarLinks } from "./SidebarList";
import useAuth from "../../hooks/useAuth";
import { UnitCoordinatorLinks } from "./SidebarList";
import useWindowSize from "../../hooks/useWindowSize";

function DashboardSidebar({
  openSidebar,
  setOpenSidebar
}) {
  const windowSize = useWindowSize()
  const path = window.location.pathname?.split("/");
  const lastPath = path[path?.length - 1];
  const { auth } = useAuth()
  // console.log(auth);
  // console.log("lastPath", lastPath);
  return (
    <div className={`min-h-screen border-r fixed ${windowSize.width > 1024 ? "w-2/12" : "z-[10] w-[60%]"}  bg-white py-[10px] pl-[10px]`}>
      <a href="/" className="cursor-pointer hidden md:block ">
        <img src={logo} alt="" />
      </a>
      <div className=" mt-16 md:mt-[38px] mr-[10px]">
        {(auth?.roles === "ADMINISTRATOR" ? AdminSidebarLinks : (auth?.roles === "UNITCOORDINATOR" ? UnitCoordinatorLinks : UsersSidebarLinks))?.map((link) => (
          <a
            href={`/dashboard${link.link}`}
            onClick={() => setOpenSidebar(false)}
            key={link.name}
            className={`flex items-center text-[12px] md:text-[16px] gap-[10px] md:gap-[15px] py-[10px] md:py-[15px] pl-2 rounded-[8px] space-y-[20px] ${lastPath === "dashboard" && link.link === ""
              ? "bg-[#0D6EFD] text-white"
              : lastPath === link.link.replace("/", "")
                ? "bg-[#0D6EFD] text-white"
                : ""
              }`}
          >
            {link.icon}
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );
}

export default DashboardSidebar;
