
import { FaEnvelope, FaLock, FaEye, FaEyeSlash, FaKey } from "react-icons/fa";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import "./style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../../config.json";
import logo from "../../img/logo3.png";

function LoginForm() {
  const { ApiUrl, setAuth, adminRoles, setAdminRoles, setUserRole } = useAuth();
  const LOGIN_URL = ApiUrl + "/api/v1/Account/login";

  const [state, setState] = useState("email");
  const [showPassword, setShowPassword] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  const initialValues = {
    email: "",
    password: "",
    pin:""
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getRoles = async () => {
      await axios
        .get(configData.links.api + "api/v1/users/getUserTypes")
        .then((res) => {
          let roles = res.data.data;
          console.log(roles);
          const normalUser = roles?.shift();
          setUserRole(normalUser.name);
          window.localStorage.setItem(
            "normalUser",
            JSON.stringify(normalUser.name)
          );
          const admins = roles?.map((admin) => admin.name);
          setAdminRoles(admins);
          window.localStorage.setItem("adminUser", JSON.stringify(admins));
        });
    };
    getRoles();
  }, []);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [formData.email, formData.password, formData.pin]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (state === "email" && formData.email.trim() === "") {
      setErrMsg("Email is required");
      return;
    } else if (state === "pin" && formData.pin.trim() === "") {
      setErrMsg("Pin is required");
      return;
    }

    if (formData.password.trim() === "") {
      setErrMsg("Password is required");
      return;
    }

    setLoading(true);
    const { email, password, pin } = formData;
    const data = state === "email" ? { email, pin, password } : { pin, email, password };

    await axios
      .post(LOGIN_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const { token, userDetails, userRole } = response.data;
        const roles = userDetails?.userRole;

        if (roles === "Nobody") {
          toast.info("User role has not been assigned.");
          window.localStorage.removeItem("auth");
        }

        setAuth({ email, password, roles, token, userRole, userDetails });

        window.localStorage.setItem(
          "auth",
          JSON.stringify({ email, password, roles, token, userDetails })
        );
        setFormData(initialValues);

        if (response.status === 200) {
          navigate("/dashboard");
        }
      })

      .catch((err) => {
        if (!err?.response) {
          toast.error("No Server Response");
          setLoading(false);
        } else if (err.response?.status === 400) {
          toast.error("Incorrect Email or Password");
          setLoading(false);
        } else if (err.response?.status === 401) {
          toast.error("Unauthorized");
          setLoading(false);
        } else {
          toast.error("Login Failed");
          setLoading(false);
        }
      });
  };

  return (
    <>
      <a href="/" className=" flex justify-center mt-5 mb-5">
        <img src={logo} alt="logo" className=" w-[80px]" />
      </a>
      <div className="form-wrapper mt-10 rounded-lg md:mt-0 mb-40 md:mb-0">
        <div className="login rounded-lg form-container">
          <div className="form-content">
            <div className=" mb-5 ">
              {errMsg ? (
                <p ref={errRef} className="errMsg">
                  {errMsg}
                </p>
              ) : (
                <div>
                  <p className=" text-[12px] md:text-[20px] font-bold text-black">
                    Login
                  </p>
                </div>
              )}
            </div>
            <div className=" gap-8 mb-8">
              <span
                className={`cursor-pointer text-[12px] md:text-[12px] text-black font-medium  border flex items-center py-2 md:py-3 justify-center px-2 rounded-md ${
                  state === "email" && "bg-[aliceblue]"
                }`}
                onClick={() => setState("email")}
              >
                Continue with email
              </span>
              <span
                className={`cursor-pointer text-[12px] text-black font-medium md:text-[12px] flex items-center border py-2 md:py-3 justify-center px-2 mt-3 rounded-md ${
                  state === "pin" && "bg-[aliceblue] "
                }`}
                onClick={() => setState("pin")}
              >
                Continue with pin
              </span>
            </div>
            <form className="login-inputFields mb-3">
              {state === "email" && (
                <div className="">
                  <h1 className=" mb-2 text-black font-bold">Email</h1>
                  <input
                    placeholder="sample@mail.com"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleInputChange(e)}
                    ref={userRef}
                    required
                    className="border-2 rounded-lg outline-none  mb-0 "
                  />
                </div>
              )}
              {state === "pin" && (
                <div className="">
                  <h1 className=" mb-2 text-black font-bold">Pin</h1>
                  <input
                    placeholder="Pin"
                    type="text"
                    name="pin"
                    value={formData.pin}
                    onChange={(e) => handleInputChange(e)}
                    ref={userRef}
                    required
                    className="border-2 rounded-lg outline-none mb-0  "
                  />
                </div>
              )}
              <div>
                <h1 className=" mb-2 text-black font-bold">Password</h1>
                <div className=" flex items-center">
                  <input
                    placeholder="******"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={(e) => handleInputChange(e)}
                    required
                    className="border-2 rounded-lg mb-0 "
                  />
                  <div
                    className=" cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <FaEyeSlash className=" text-[20px] ml-[-40px]" />
                    ) : (
                      <FaEye className=" text-[20px] ml-[-40px]" />
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className=" flex justify-end">
              <Link
                className="text-[#F14336] font-medium"
                to="/forgot-password"
              >
                Forgot password?
              </Link>
            </div>
            <button
              className=" bg-[#0D6EFD] rounded-md text-white py-2 font-bold m-0  w-full"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading..." : "LOGIN"}
            </button>
          </div>
          <div className=" flex justify-center gap-10">
            <h1>Not a Marshal Yet?</h1>
            <Link
              className=" font-bold text-[14px] text-[#0D6EFD] "
              to="/signup"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
