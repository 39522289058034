import React, { useEffect } from "react";
import MarshalDocuments from "../components/Documents/MarshalDocuments";

function MarshalDocumentsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <MarshalDocuments />;
}

export default MarshalDocumentsPage;
