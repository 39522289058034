import apiClient from "../../api/axios";

export const getAllMarshals = async () => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  return await apiClient.get(`/api/v1/users/getAllUsers`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
  .catch((err) => {
    if (err?.response?.status == 401) {
      window.localStorage.removeItem("auth");
      window.location.href = "/login";
    }
  });
};
