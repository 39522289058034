import axios from "axios";
import React from "react";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

function CreateMarshal({ closeModal, roles }) {
  const [singleMarshal, setSingleMarshal] = useState({
    paid: "",
  });
  const userType = roles.filter((u) => u !== "Nobody");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSingleMarshal((user) => {
      return { ...user, [name]: value };
    });
  };
  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState({});
  const [percent, setPercent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusResult, setStatusResult] = useState({});

  const { ApiUrl, auth, getAllMarshals, units } = useAuth();

  const uploadRef = React.useRef();

  const handleUpload = (res) => {
    const file = uploadRef.current.files[0];
    setFile(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("DocumentType", "ProfilePicture");
    formData.append("UserId", `${res?.data?.data?.UserId}`);
    axios
      .post(ApiUrl + `/api/v1/Documents/uploadFile`, formData, {
        onUploadProgress: (data) => {
          setPercent(Math.round((data.loaded / data.total) * 100));
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {
        const { Id, FileName, Path } = res.data.data;
        setUploadedFile({ Id, FileName, Path });
        setSingleMarshal({ ...res?.data?.data, ProfilePictureUrl: Path });
        // setPercent(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const checkStatus = async (text, field) => {
    let textNew = encodeURIComponent(text);
    await axios
      .get(ApiUrl + `/api/v1/Account/check?SearchText=${textNew}`, {
        headers: {
          Authorization: "Bearer " + auth?.token,
        },
      })
      .then((res) => {
        if (field === "username") {
          setStatusResult({ ...statusResult, username: res.data.data });
        } else {
          setStatusResult({ ...statusResult, email: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await axios
      .post(
        ApiUrl + "/api/v1/SpecialMarshals/add",
        {
          groupId: 0,
          smNumber: singleMarshal.smNumber,
          firstname: singleMarshal.firstname,
          lastname: singleMarshal.lastname,
          userName: singleMarshal.userName,
          emailAddress: singleMarshal.emailAddress,
          password: singleMarshal.password,
          doB:
            singleMarshal.doB === ""
              ? new Date().toLocaleDateString()
              : singleMarshal.doB,
          address: singleMarshal.address,
          profession: singleMarshal.profession,
          userType: singleMarshal.userType,
          paid: singleMarshal.paid === "" ? false : Boolean(singleMarshal.paid),
          phoneNumber: singleMarshal.phoneNumber,
          alternativeNumber: singleMarshal.alternativeNumber,
          sex: singleMarshal.sex,
          unitId: parseInt(auth.userDetails.UnitId),
        },
        {
          headers: {
            Authorization: "Bearer " + auth?.token,
          },
        }
      )
      .then((res) => {
        setIsSubmitting(false);
        if (res.status === 200) {
          closeModal();
          getAllMarshals();
          handleUpload(res);
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (err?.response?.data?.status === 400) {
          toast.error(`Please fill all input fields`);
        }
      });
  };
  return (
    <>
      <div
        style={{ top: 0, padding: 0, marginTop: 2 + "rem" }}
        className="edit result-container"
      >
        {/* <ToastContainer /> */}
        <div className="result-content row">
          <div className="result-image col-sm-4 bg-primary">
            <label for="profilePic" className="profile-pic-container">
              {percent === 100 ? (
                <img src={uploadedFile.Path} alt="" />
              ) : (
                <>
                  <img
                    src={singleMarshal.ProfilePictureUrl}
                    alt=""
                    style={{ position: "relative", cursor: "pointer" }}
                  />
                  <button className="overlay-placeholder">
                    Change Picture
                  </button>
                </>
              )}
              <input
                type="file"
                name="profilePic"
                id="profilePic"
                ref={uploadRef}
                onChange={handleUpload}
              />
            </label>
            {percent && (
              <div
                className="progress mt-2"
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: -26 + "px",
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${percent}%` }}
                >
                  {`${percent}%`}
                </div>
              </div>
            )}
          </div>
          <form className="col-sm-8" onSubmit={handleSubmit}>
            <div className="result-text row">
              <div className="col-sm-6">
                <h5>Group ID</h5>
                <input
                  className="r-text"
                  name="groupId"
                  value={singleMarshal.groupId}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="col-sm-6">
                <h5>SM Number</h5>
                <input
                  className="r-text"
                  name="smNumber"
                  value={singleMarshal.smNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-6">
                <h5>Firstname</h5>
                <input
                  className="r-text"
                  name="firstname"
                  value={singleMarshal.firstname}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-sm-6">
                <h5>Lastname</h5>
                <input
                  className="r-text"
                  name="lastname"
                  value={singleMarshal.lastname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-sm-6">
                <h5>Username</h5>
                <input
                  className="r-text"
                  placeholder="More than 8 character words"
                  name="userName"
                  value={singleMarshal.userName}
                  onChange={handleChange}
                  onBlur={(e) => checkStatus(e.target.value, "username")}
                  required
                  minLength={8}
                />
                {statusResult?.username?.length > 0 ? (
                  <p
                    style={{
                      marginTop: "-15px",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {statusResult?.username}
                  </p>
                ) : null}
              </div>
              <div className="col-sm-6">
                <h5>DOB</h5>
                <input
                  className="r-text"
                  name="doB"
                  type="date"
                  value={singleMarshal.doB}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-sm-6">
                <h5>Email Address</h5>
                <input
                  type="email"
                  className="r-text"
                  name="emailAddress"
                  value={singleMarshal.emailAddress}
                  onChange={handleChange}
                  onBlur={(e) => checkStatus(e.target.value, "email")}
                  required
                />
                {statusResult?.email?.length > 0 ? (
                  <p
                    style={{
                      marginTop: "-15px",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {statusResult?.email}
                  </p>
                ) : null}
              </div>
              <div className="col-sm-6">
                <h5>Gender</h5>
                <select
                  name="sex"
                  className="r-text"
                  value={singleMarshal.sex}
                  onChange={handleChange}
                  required
                >
                  <option value="" selected hidden disabled>
                    Select gender
                  </option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </div>
              <div className="col-sm-6">
                <h5>Password</h5>
                <input
                  className="r-text r-pswd"
                  name="password"
                  value={singleMarshal.password}
                  onChange={handleChange}
                  required
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                  // minLength={8}
                />
                <p
                  style={{ marginTop: "-15px", color: "red", fontSize: "12px" }}
                >
                  1 uppercase letter, 1 lowercase letter, 1 number, at least 8
                  characters
                </p>
              </div>
              <div className="col-sm-6">
                <h5>Address</h5>
                <input
                  className="r-text"
                  name="address"
                  value={singleMarshal.address}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-sm-6">
                <h5>Phone Number</h5>
                <input
                  className="r-text"
                  name="phoneNumber"
                  value={singleMarshal.phoneNumber}
                  onChange={handleChange}
                  required
                  minLength={11}
                  maxLength={11}
                />
              </div>
              <div className="col-sm-6">
                <h5>Alternative Phone Number</h5>
                <input
                  className="r-text"
                  name="alternativeNumber"
                  value={singleMarshal.alternativeNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-6">
                <h5>Profession</h5>
                <input
                  className="r-text"
                  name="profession"
                  value={singleMarshal.profession}
                  onChange={handleChange}
                />
              </div>
              <div className="col-sm-6">
                <h5>Marshal Type</h5>
                <select
                  name="userType"
                  className="r-text"
                  value={singleMarshal.userType}
                  onChange={handleChange}
                  required
                >
                  <option value="" selected hidden disabled>
                    Select type
                  </option>
                  {userType.map((type, index) => {
                    let trimmed = type.replaceAll(" ", "");
                    return (
                      <>
                        <option value={trimmed} key={index}>
                          {type}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-6">
                <h5>Paid</h5>
                <select
                  className="r-text"
                  name="paid"
                  value={singleMarshal.paid}
                  onChange={handleChange}
                >
                  <option value={false}>False</option>
                  <option value={true}>True</option>
                </select>
              </div>
              {auth.roles === "ADMINISTRATOR" && (
                <div className="col-sm-6">
                  <h5>Unit</h5>
                  <select
                    name="unit"
                    className="r-text"
                    value={singleMarshal.unit}
                    onChange={handleChange}
                    required
                  >
                    <option value="" selected hidden disabled>
                      Select unit
                    </option>
                    {units.map((type, index) => {
                      return (
                        <>
                          <option value={type.Id} key={index}>
                            {type.Name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
              )}
              <button
                className="btn btn-primary mt-4 my-4"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Please wait...." : "Save Changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateMarshal;
