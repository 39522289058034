import React, { useEffect, useLayoutEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import SimpleUpload from "../FileUploads/SimpleUpload";
import imageBg from "../../img/image.png";
import { TfiTrash } from "react-icons/tfi";
import useAuth from "../../hooks/useAuth";
import { addMarshal } from "../../utils/marshals/addMarshal";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import { addCategory } from "../../utils/category/addCategory";
import { getAllCategory } from "../../utils/category/getAllCategory";
import { updateCategory } from "../../utils/category/updateCategory";

function AddCategorys({ setData, closeModal, mode, rowData }) {
  const [formData, setFormData] = useState({
    Name: "",
    Description: "",
    id: rowData?.id,
  });
  const [images, setImages] = useState([]);
  // const [marshalDetail, setMarshalDetail] = useState(null);
  const { getRoles } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (mode === "edit") {
      await updateCategory(formData, rowData?.Id)
        .then((res) => {
          setLoading(false);
          toast.success("Category updated successfully");
          closeModal(false);
          getAllCategory().then((res) => {
            setData(res?.data?.data);
          });
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error adding a category:", err);
          toast.error(`${err.response.data.message}`);
        });
    } else {
      await addCategory(formData)
        .then((res) => {
          setLoading(false);
          toast.success("Category added successfully");
          getAllCategory().then((res) => {
            setData(res?.data?.data);
          });
          closeModal(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error adding category:", err);
          toast.error(`${err.response.data.message}`);
        });
    }
  };

  useLayoutEffect(() => {
    getRoles();
    setFormData({
      Name: rowData?.name || "",
      Description: rowData?.description || "",
      // location: rowData?.Location || "",
      id: rowData?.id || "",
    });
  }, [rowData]);

  return (
    <div className="p-8 !h-[200px] !text-black">
      <div className="flex justify-between items-center mb-4 text-[18px] text-black">
        <h3 className="font-semibold ">
          {mode === "edit" ? "Edit" : mode === "view" ? "View" : "Add"} Category
        </h3>
        <FaTimes
          onClick={() =>
            mode
              ? closeModal({ modalName: "", showModal: false })
              : closeModal(false)
          }
          className="cursor-pointer"
        />
      </div>
      <form className="  gap-[16px]" onSubmit={handleAddCategory}> 
        <div className="">
          <label className="font-semibold text-[16px] mb-[8px]">
            Name
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="Name"
            value={formData?.Name}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>

        <div className=" mt-5">
        <label className="font-semibold text-[16px] mb-[8px]">
          Description
        </label>
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="Description"
          value={formData?.Description}
          onChange={handleChange}
          required
          readOnly={mode === "view"}
        />
      </div>

        {mode !== "view" && (
          <button className=" col-span-2 w-full mt-5 rounded-[8px] bg-[#0D6EFD] text-white text-[16px] py-[16px]">
            {loading ? <Loader /> : "Submit"}
          </button>
        )}
      </form>
    </div>
  );
}

export default AddCategorys;
