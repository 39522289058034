import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import useAuth from "../../hooks/useAuth";
import Table from "../common/Table";
import { HiOutlineSearch } from "react-icons/hi";
import ModalContainer from "../common/ModalContainer";
import ConfirmationModal from "../common/ConfirmationModal";
import { toast } from "react-toastify";
import { getAllOffences } from "../../utils/offences/getAllOffence";
import { deleteOffence } from "../../utils/offences/deleteOffence";
import AddOffence from "../Forms.js/AddOffence";


function ManageOffences() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const { getRoles, units, setUnits, filterMarshalByUnitId } = useAuth();
const [offence, setoffence] = useState();
  
  useEffect(() => {
    setloading(true)
    getAllOffences()
      .then((res) => {
        setloading(false)
        setoffence(res?.data?.data);
      })
      .catch(() => {
        console.log("error fetching offences");
        setloading(false);
      });
    getRoles();
  }, [modalIsOpen]);
  


  
  // const [selectedUnit, setSelectedUnit] = useState("");
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const filterMarshalByUnit = (unit) => {
  //   setSelectedUnit(unit);
  //   if (unit !== "") {
  //     filterMarshalByUnitId(unit);
  //   } else {
  //     getAllMarshals();
  //   }
  // };

  const handleOffenceDelete = () => {
    setloading(true);
    deleteOffence(rowData?.id)
      .then((res) => {
        setloading(false);
        setOpenModal({ modalName: "", showModal: false });
        toast.success("Offence deleted successfully");
        getAllOffences()
          .then((res) => {
            setoffence(res?.data?.data);
        })
          .catch(() => {
            console.log("error fetching offence");
            setloading(false);
          });
      })
      .catch((err) => {
        console.log("error deleting offence", err);
        setloading(false);
      });
  };

// console.log(loading);
  
  return (
    <div className="min-h-[92vh]">
      <div className="flex justify-between items-center mb-[30px]">
        <div className="relative flex gap-[21px] items-center">
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
      
        <button
          className="rounded-[8px] bg-[#0D6EFD] text-[16px] text-white px-3 py-[10px] flex items-center gap-2"
          onClick={() => setModalIsOpen(true)}
        >
          Add Offence <span className="text-[28px] font-semibold">+</span>
        </button>
 
      </div>
      <Table
      state={loading}
        header={[
          { name: "Infrigement", identifier: "infrigement" },
          { name: "Code", identifier: "code" },
          { name: "Points", identifier: "points" },
          { name: "Penalty", identifier: "penalty" },
          { name: "Category", identifier: "categoryId" },
        ]}
        data={offence}
        options={{
          variant: "primary",
          tableStyle: "",
          allowSorting: true,
          allowActions: true,
          actions: {
            edit: true,
            view: true,
            delete: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        searchQuery={searchQuery}
        
      />
      {modalIsOpen && (
        <ModalContainer>
          <AddOffence setData={setoffence} closeModal={setModalIsOpen} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "edit" && (
        <ModalContainer>
          <AddOffence setData={setoffence} mode="edit" closeModal={setOpenModal} rowData={rowData} />
        </ModalContainer>
      )}

      {openModal.showModal && openModal.modalName === "view" && (
        <ModalContainer>
          <AddOffence mode="view" closeModal={setOpenModal} rowData={rowData} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "delete" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to delete this Offence?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={handleOffenceDelete}
            loading={loading}
          />
        </ModalContainer>
      )}
    </div>
  );
}

export default ManageOffences;
