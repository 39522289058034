import React from 'react'
import ForgotPword from '../components/ForgotPword'
import Navbar from "../components/Navbar";

function ForgotPassword() {
  return (
    <main>
        <Navbar/>
        <ForgotPword/>
    </main>
  )
}

export default ForgotPassword