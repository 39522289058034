import React, { useEffect } from "react";
import Units from "../components/Units";

function UnitsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Units />;
}

export default UnitsPage;
