import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import useAuth from '../../hooks/useAuth'
import DragAndDropUpload from '../FileUploads/DragAndDropUpload'
import { toast } from 'react-toastify';
import { uploadFile } from '../../utils/documents/uploadFile';
import Loader from '../common/Loader';
import { getMyDocuments } from '../../utils/documents/getMyDocuments';

function AddDocument({ closeModal }) {
    const [loading, setLoading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null)
    const [selectedType, setSelectedType] = useState("")
    const { docType, auth, setMyDocuments } = useAuth()
    const handleUploadFunction = async (e) => {
        e.preventDefault()
        const files = uploadedFile[0];
        console.log("files", files);
        setLoading(true);
        toast
            .promise(
                uploadFile(
                    files,
                    5,
                    ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx"],
                    selectedType,
                    auth?.userDetails?.id
                        ? auth?.userDetails?.id
                        : auth?.userDetails?.id
                ),
                {
                    pending: "Uploading file to database, Please wait...",
                    success: "File Uploaded Successfully",
                    error: "Failed to Upload File",
                }
            )
            .then((res) => {
                console.log("res", res?.data?.data);
                closeModal(false)
                setLoading(false);
                getMyDocuments().then((res) => {
                    setMyDocuments(res?.data?.data)
                })
            })
            .catch(() => {
                setLoading(false);
            });
    };
    return (
        <div className="p-8 !text-black">
            <div className="flex justify-between items-center mb-4 text-[18px] text-black">
                <h3>Add Document</h3>
                <FaTimes onClick={() => closeModal(false)} className="cursor-pointer" />
            </div>
            <form>
                <div className="mb-[22px]">
                    <label className="font-semibold text-[16px] mb-[8px]">Name</label>
                    <input
                        className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
                        name="title"
                        // value={formData.title}
                        // onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-[22px]">
                    <label className="font-semibold text-[16px] mb-[8px]">Document Type</label>
                    <select
                        className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7] bg-transparent"
                        name="title"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        required
                    >
                        <option value="" disabled selected>Select type</option>
                        {docType?.map((type) => (
                            <option value={type?.name}>{type?.name}</option>
                        ))}
                    </select>
                </div>
                <DragAndDropUpload handleUploadFunction={setUploadedFile} />
                <button className='text-white shadow-md flex justify-center rounded-md bg-[#0D6EFD] font-semibold cursor-pointer py-2 w-full mt-8' onClick={handleUploadFunction}>
                    {loading ? <Loader /> : "Submit"}
                </button>
            </form>
        </div>
    )
}

export default AddDocument