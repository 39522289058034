import axios from "axios";
import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import Modal from "react-modal";
import "./SMReports.css";
import Table from "./Table";

function SMReports() {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "#ffffff5c";
  const [editModal, setEditModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div className="report-header mainContent-header">
        <h2>My Reports</h2>
        <div className="mainContent-EditProfile--btn">
          <button className="btn btn-primary" onClick={openModal}>
            Create New Report
          </button>
        </div>
      </div>

      <div className="mainContent-EditProfile--btn">
        <>
          <Table />
        </>
      </div>
      <Modal
        isOpen={modalIsOpen}
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={closeModal}
      >
        <div className="">
          <AddReportForm closeModal={closeModal} />
        </div>
      </Modal>
      {/* {modalIsOpen && (
        <div className="Overlay">
          <AddReportForm closeModal={closeModal} />
        </div>
      )} */}
      {editModal && (
        <div className="Overlay">
          <EditReportForm />
        </div>
      )}
    </>
  );
}

export default SMReports;

const AddReportForm = ({ closeModal }) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const { getMyReports, setMyReports, ApiUrl } = useAuth();
  const [report, setReport] = useState({
    NoOfSMPresent: "1",
    Title: "",
    Location: "",
    Incident: "",
    DocumentId: "",
  });

  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState({});
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(null);

  const uploadRef = React.useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setReport({ ...report, [name]: value });
  };
  const UploadFile = () => {
    const file = uploadRef.current.files[0];
    setFile(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(ApiUrl + `/api/v1/Documents/uploadFile`, formData, {
        onUploadProgress: (data) => {
          setPercent(Math.round((data.loaded / data.total) * 100));
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const { Id, FileName, Path } = res.data.data;
        setUploadedFile({ Id, FileName, Path });
      });
  };
  const addNewReport = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        ApiUrl + `/api/v1/Reports/add`,
        {
          noOfSMPresent: report.NoOfSMPresent,
          title: report.Title,
          location: report.Location,
          incident: report.Incident,
          documentId: uploadedFile.Id,
        },
        {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          closeModal();
          getMyReports();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const headerStyle = {
    marginBottom: 1 + "rem",
    marginTop: 1 + "rem",
  };
  return (
    <div className="addReportForm-Container Modal">
      <button className="btn btn-primary" onClick={closeModal}>
        Close
      </button>
      {loading ? (
        "Please wait... Submitting your report"
      ) : (
        <>
          <h3 style={headerStyle}>Add New Report</h3>
          <form action="" className="row">
            <div className="formInput col-sm-6">
              <label>Number of SM Present: </label>
              <input
                type="number"
                name="NoOfSMPresent"
                value={report.NoOfSMPresent}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="formInput col-sm-6">
              <label>Title</label>
              <input
                type="text"
                name="Title"
                value={report.Title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="formInput col-sm-12">
              <label>Incident</label>
              <textarea
                type="text"
                name="Incident"
                value={report.Incident}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="formInput col-sm-6">
              <label>Location</label>
              <input
                type="text"
                name="Location"
                value={report.Location}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="formInput col-sm-6">
              <label className="upload-file-label">Choose Document</label>
              <div className="upload-file-input-container">
                <input
                  className="upload-file-input"
                  multiple
                  type="file"
                  ref={uploadRef}
                  onChange={UploadFile}
                />
              </div>
            </div>
            {percent && (
              <div className="progress mt-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${percent}%` }}
                >
                  {`${percent}%`}
                </div>
              </div>
            )}
          </form>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={addNewReport}
          >
            SAVE
          </button>
        </>
      )}
    </div>
  );
};

const EditReportForm = () => {
  return (
    <div className="addReportForm-Container Modal">
      <form action="">
        <h3>Add New Report</h3>
        <label>Number of SM Present: </label>
        <select name="" id="">
          <option value="1">1</option>
          <option value="1">2</option>
          <option value="1">3</option>
          <option value="1">5</option>
          <option value="1">6</option>
          <option value="1">7</option>
        </select>
        <label>Title</label>
        <input type="text" name="" id="" />
        <label>Location</label>
        <input type="text" name="" id="" />
        <label>Incident</label>
        <textarea type="text" name="" id="" />
        <button type="submit">SAVE</button>
      </form>
    </div>
  );
};
