import React from 'react'
import ManageCategory from '../components/AdminDashBoard/manageCategory'

function CategoryPage() {
  return (
    <div className='min-h-[92vh] text-[16px]'>
      <ManageCategory/>
    </div>
  )
}

export default CategoryPage