import apiClient from "../../api/axios";

export const getSMDocuments = async (userId) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  return await apiClient.get(`/api/v1/Documents/getSMDocuments?userId=${userId}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
