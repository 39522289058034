import React, { useEffect } from "react";
import SystemLogs from "../components/SystemLogs/SystemLogs";

function SystemLogsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <SystemLogs />;
}

export default SystemLogsPage;
