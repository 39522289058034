import React, { useState } from "react";
import "./style.css";

import SMInformation from "./SMDashboardInfo";
import useAuth from "../../hooks/useAuth";

function SMDashboard() {
  const { updateProfile, isEditing, setIsEditing, loading } = useAuth();

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };
  // const postChanges = () => {
  //   updateProfile();
  //   setIsEditing(!isEditing);
  // };
  return (
    <>
      <div className="mainContent-header">
        <h2>Dashboard</h2>
      </div>
      <div className="mainContent-EditProfile--btn">
        <button
          className="btn btn-primary"
          onClick={isEditing ? updateProfile : handleEdit}
        >
         {isEditing
            ? loading
              ? "Saving..."
              : "Save Changes"
            : "Edit My Profile"}
        </button>
        {isEditing && (
          <button
            className="btn btn-primary"
            style={{ marginLeft: 0.5 + "rem" }}
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </button>
        )}
      </div>
      <div className="mainContent-profileInformation">
        <SMInformation isEditing={isEditing} />
      </div>
    </>
  );
}

export default SMDashboard;
