import React from "react";


function index() {
  return (

      <section id="location" className="w-full">
        <div className="">
          <div className="row ">
            <div
              className=" text-center mb50  fadeInDown animated w-full"
              data-wow-duration="500ms"
            >
              <p>&nbsp;</p>
              <div id="map_canvas w-full">
                <iframe
                  className="map w-full"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.056791158658!2d3.363001299999999!3d6.639870099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b939239b03539%3A0x3596114b11e07c6f!2s2%20Akinsanya%20St%2C%20Ojodu%20101233%2C%20Ojodu%20Berger!5e0!3m2!1sen!2sng!4v1662058993590!5m2!1sen!2sng"
                  title="map"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

  );
}

export default index;
