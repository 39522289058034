import React from 'react'
import ProfileHome from '../components/profile/home'

const ProfilePage = () => {
  return (
    <div className='  bg-white text-black rounded-lg p-5 md:p-10'>
        <ProfileHome/>
    </div>
  )
}

export default ProfilePage