import apiClient from "../../api/axios";

// export const getMarshalById = async (id) => {
//   const user = JSON.parse(window.localStorage.getItem("auth"));
//   const token = user?.token;
//   return await apiClient
//     .post(`/api/v1/users/getByuserId`, {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     })
//     .catch((err) => {
//       if (err?.response?.status == 401) {
//         window.localStorage.removeItem("auth");
//         window.location.href = "/login";
//       }
//     });
// };

export const getMarshalById = async (id) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  const data = {
    value:id
  }
  return await apiClient.post(`/api/v1/users/getByuserId`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
