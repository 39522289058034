import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import logo from "../../img/logo.png";
function ForgotPwd() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { auth, ApiUrl } = useAuth();

  useEffect(() => {
    setMsg("");
    setErrMsg("");
  }, [email]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        ApiUrl + `/api/v1/Account/forgotPassword`,
        { Email: email },
        {
          headers: {
            Authorization: "Bearer " + auth?.accessToken,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        setMsg(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        setErrMsg("Invalid Email Address");
      }
      setLoading(false);
    }
  };
  return (
    <div className="form-wrapper">
      <div className="login form-container">
        <div className="form-content">
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </Link>
          {msg && (
            <p
              style={{
                background: "green",
                padding: 0.5 + "rem",
                color: "white",
                marginBottom: 1 + "rem",
              }}
            >
              {msg}
            </p>
          )}
          {errMsg && (
            <p
              style={{
                background: "red",
                padding: 0.5 + "rem",
                color: "white",
                marginBottom: 1 + "rem",
              }}
            >
              {errMsg}
            </p>
          )}
          <form className="login-inputFields">
            <input
              placeholder="Email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </form>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Reset Password"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPwd;
