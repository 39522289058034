import React from "react";
import { useAppState } from "../../context/AppStates";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../img/logo.png";
import useAuth from "../../hooks/useAuth";
function SideNav() {
  const { switchTab } = useAppState();
  const { auth, setAuth, adminRoles } = useAuth();
  const user = JSON.parse(window.localStorage.getItem("auth"));

  let navigate = useNavigate();
  const handleLogout = () => {
    setAuth({});
    window.localStorage.removeItem("auth");
    navigate("/login");
  };
  return (
    <div className="">
      
    </div>
  );
}

export default SideNav;
