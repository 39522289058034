import React from 'react'
import ErrorPage from '../components/404_page'

function Error() {
  return (
    <div>
        <ErrorPage/>
    </div>
  )
}

export default Error