import React from 'react'
import folderImage from "../../img/folder_open.webp"

function MarshalDocumentCardView({ setOpenSingleMarshalView, user, setSelectedUser }) {
    return (
        <div className='bg-white rounded-[8px] h-[170px] md:h-[222px]  p-[18px] break-words text-center flex justify-start flex-col items-center cursor-pointer' onClick={() => { setOpenSingleMarshalView(true); setSelectedUser(user) }}>
            <>
                <img src={folderImage} alt="" className=' h-[40px] md:h-[100px]'/>
            </>
            {user?.profilePictureUrl !== "" ? <img src={user?.profilePictureUrl
} alt='user' className=' w-[30px] h-[30px] md:w-[49px] md:h-[53px] rounded-full mt-[12px] mb-[12px]' /> :
                <div className='h-[30px] w-[30px] md:w-[49px] md:h-[53px] rounded-full bg-blue-300 mt-[12px] mb-[12px]'></div>
            }
            <p className=' text-[13px] md:text-[16px] text-black'>{user?.firstName} {user?.lastName}</p>
        </div>
    )
}

export default MarshalDocumentCardView