

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';

const Step2 = ({setCurrentStep}) => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the dropped files
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className=' w-full md:w-[700px]'>
      <div className='grid grid-cols-2 gap-3 shadow-sm mb-10 mx-2'>
        <div className='bg-[#abc8f4] py-[3px] rounded-xl'></div>
        <div className='bg-[#0D6EFD] py-[3px] rounded-xl'></div>
      </div>
      <div className=' w-full md:w-[1/3] px-10 py-5 shadow-2xl'>
        <span className='flex gap-1 items-center mb-4 cursor-pointer' onClick={()=>setCurrentStep(1)}>
          <IoIosArrowBack className='' />
          <p>Back</p>
        </span>
        <h1 className='mb-5 text-[13px] font-bold text-gray-700'>Upload files</h1>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 my-5'>
          <div className='w-full'>
            <h1 className='font-bold text-[12px] text-black mb-1'>Driver's License</h1>
            <div {...getRootProps()} className='border flex flex-col items-center justify-center py-5 border-dashed border-gray-300 px-2 rounded-md'>
              <input {...getInputProps()} />
              <i className=' mb-2 text-[20px]'><MdOutlineFileUpload /></i>
              <p className=' text-black text-[10px] font-bold'>Drag and Drop <span className=' text-gray-400'>or choose file</span>  To Upload</p>
            </div>
          </div>
          <div className='w-full'>
            <h1 className='font-bold text-black ext-[12px] mb-1'> Passport Photograph</h1>
            <div {...getRootProps()} className='border flex flex-col items-center justify-center py-5 border-dashed border-gray-300 px-2 rounded-md'>
              <input {...getInputProps()} />
              <i className=' mb-2 text-[20px]'><MdOutlineFileUpload /></i>
              <p className=' text-black text-[10px] font-bold'>Drag and Drop <span className=' text-gray-400'>or choose file</span>  To Upload</p>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 mb-5'>
          <div className='w-full'>
            <h1 className='font-bold text-[12px] text-black mb-1'>Road Worthiness</h1>
            <div {...getRootProps()} className='border flex flex-col items-center justify-center py-5 border-dashed border-gray-300 px-2 rounded-md'>
              <input {...getInputProps()} />
              <i className=' mb-2 text-[20px]'><MdOutlineFileUpload /></i>
              <p className=' text-black text-[10px] font-bold'>Drag and Drop <span className=' text-gray-400'>or choose file</span>  To Upload</p>
            </div>
          </div>
          <div className='w-full'>
            <h1 className='font-bold text-[12px] text-black mb-1'> Car License</h1>
            <div {...getRootProps()} className='border flex flex-col items-center justify-center py-5 border-dashed border-gray-300 px-2 rounded-md'>
              <input {...getInputProps()} />
              <i className=' mb-2 text-[20px]'><MdOutlineFileUpload /></i>
              <p className=' text-black text-[10px] font-bold'>Drag and Drop <span className=' text-gray-400'>or choose file</span>  To Upload</p>
            </div>
          </div>
        </div>


        <button className='text-white shadow-md flex justify-center rounded-md bg-[#0D6EFD] font-semibold cursor-pointer py-2 w-full'>
          Sign up
        </button>
        <p className=' mt-5'>
        Already have an account?
        <Link className="text-[#0D6EFD] ml-5 font-medium" to="/Login">
          Login
        </Link>
        </p>
        

      </div>
    </div>
  );
}

export default Step2;
