import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import Loader from "../../common/Loader";
import { ResetPasswordbyUSer } from "../../../utils/profile/resetPassword";
import { toast } from "react-toastify";

const Section3 = ({ State1 }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [password, setPassword] = useState(""); // Initial password value
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    const email = State1?.emailAddress;
    try {
      setIsLoading(true);
      await ResetPasswordbyUSer({ email, password });
      setIsEditing(false); // Reset editing state after update
      toast.success("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Failed to update password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    } else if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return false;
    }
    setPasswordError("");
    return true;
  };

  return (
    <div>
      <div className="flex mt-5 justify-between border items-center w-full border-slate-300 rounded-lg px-5 py-3">
        <div className="flex items-center gap-2">
          <div>
            <h1 className="font-bold">Password</h1>
            {isEditing ? (
              <>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                  className="border-b w-fit outline-none"
                />
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                  className="border-b w-fit outline-none"
                />
                {passwordError && (
                  <p className="text-red-500">{passwordError}</p>
                )}
              </>
            ) : (
              <p className="font-medium text-slate-500">
                ***********************
              </p>
            )}
          </div>
        </div>

        {isEditing ? (
          <div className="md:flex items-center gap-1">
            <button
              className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>

            <button
              className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
              onClick={() => validatePassword() && handleUpdate()}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span>Saving...</span>
                  <Loader
                    type="TailSpin"
                    color="#000000"
                    height={18}
                    width={18}
                  />
                </>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        ) : (
          <button
            className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
            onClick={() => setIsEditing(true)}
          >
            <h1 className=" hidden md:block">
              Edit 
            </h1>
            <FiEdit />
          </button>
        )}
      </div>
    </div>
  );
};

export default Section3;
