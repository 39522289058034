import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import userImage from "../../../img/user.webp";
import { UpdateSM } from "../../../utils/profile/updateSM";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { getMarshalById } from "../../../utils/marshals/getMarshalById";
import { uploadFile } from "../../../utils/documents/uploadFile";
import useAuth from "../../../hooks/useAuth";

const Section1 = ({ State1, State2, det }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userName, setUserName] = useState(State1?.UserName);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { auth, setAuth } = useAuth();

  const id = State1?.id;
  const userType = State1?.UserRole;

  console.log(State1);

  const handleUpdate = async () => {
    // Call API to update data
    try {
      setIsLoading(true);
      await UpdateSM({
        id,
        userType,
        userName,
      });
      toast.success("Updated Successfully");
      getMarshalById(id).then((res) => {
        setIsEditing(false);
        State2(res?.data?.data);
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleUploadFunction = async (e) => {
    e.preventDefault();
    const files = e.target.files[0];
    console.log("files", files);
    setLoading(true);
    toast
      .promise(
        uploadFile(
          files,
          5,
          ["jpg", "jpeg", "png"],
          "ProfilePicture",
          State1?.id
        ),
        {
          pending: "Uploading file to database, Please wait...",
          success: "File Uploaded Successfully",
          error: "Failed to Upload File",
        }
      )
      .then((res) => {
        console.log("res", res?.data?.data);
        setLoading(false);
        getMarshalById(id).then((res) => {
          setIsEditing(false);
          State2(res?.data?.data);
          setAuth((auth) => {
            const userDetails = { ...auth.userDetails };
            userDetails.profilePictureUrl = res?.data?.data?.profilePictureUrl;
            return { ...auth, userDetails };
          });
          window.localStorage.setItem("auth", JSON.stringify({
            email:auth?.email,
            token: auth?.token,
            roles:auth?.roles,
            userDetails:res?.data?.data
          }))
          setIsLoading(false);
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="flex justify-between border items-center w-full border-slate-300 rounded-lg px-5 py-3">
        <div className="flex items-center gap-2">
          <label className="cursor-pointer">
            <img
              src={
                det?.userDetails?.profilePictureUrl !== ""
                  ? det?.userDetails?.profilePictureUrl
                  : userImage
              }
              alt=""
              className="flex justifiy-center items-center !w-[50px] !h-[50px] rounded-full object-cover"
            />
            <input
              type="file"
              className="hidden"
              onChange={(e) => handleUploadFunction(e)}
            />
          </label>
          <div>
            {isEditing ? (
              <input
                type="text"
                value={userName || State1?.userName}
                placeholder={State1?.userName}
                onChange={(e) => setUserName(e.target.value)}
                className="border-b  outline-none w-fit"
              />
            ) : (
              <h1 className="font-bold">{State1?.userName}</h1>
            )}
            <h2 className="font-medium text-slate-500">{State1?.UserRole}</h2>
          </div>
        </div>

        {isEditing ? (
          <div className="md:flex items-center gap-1">
            <button
              className="flex items-center gap-1 mb-1 md:mb-0 border border-slate-300 rounded-lg p-2"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>

            <button
              className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
              onClick={handleUpdate}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader type="TailSpin" color="blue" height={18} width={18} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        ) : (
          <button
            className="flex items-center gap-1 border border-slate-300 rounded-lg p-2"
            onClick={() => setIsEditing(true)}
          >
            <h1 className=" hidden md:block">
              Edit 
            </h1>
             <FiEdit />
          </button>
        )}
      </div>
    </div>
  );
};

export default Section1;
