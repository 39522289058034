import React, { useContext, useEffect, useState } from "react";

const StateContext = React.createContext();

export function useAppState() {
  return useContext(StateContext);
}

const StateProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    window.localStorage.setItem("activeTab", JSON.stringify(activeTab));
  }, [activeTab]);
  const switchTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  

  const globalState = {
    activeTab,
    setActiveTab,
    switchTab,
  };
  return (
    <StateContext.Provider value={globalState}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
