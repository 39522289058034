import axios from "axios";

// export default axios.create({
//   baseURL:
//     process.env.NODE_ENV === "production"
//       ? process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
//         ? process.env.NEXT_PUBLIC_API_URL_PROD
//         : process.env.NEXT_PUBLIC_API_URL_DEV
//       : process.env.NEXT_PUBLIC_API_URL_DEV,
//   headers: {
//     "Content-type": "application/json",
//   },
// });

export default axios.create({
  baseURL:"https://frsc-ref.azurewebsites.net",
  headers: {
  },
});