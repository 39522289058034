import React from "react";
import bullsEye from "../../img/bullseye.png";
// import vectorImage from '../../img/vectorImage.png'
import officer2 from "../../img/about-frsc-image.webp";

function index() {
  return (
    <section id="about-us" className="">
      <div className=" mb-20 bg-[#E7F1FF]">
        <div className="container pt-2  pb-3 px-3 md:px-0 lg:pb-20 mx-auto justify-items-center grid grid-cols-1 md:grid-cols-2">
          <div className=" mb-3 lg:mb-[-120px]">
            <img
              src={officer2}
              className=" hidden md:block w-[400px]  scale-[.8]"
              alt=""
            />
          </div>
          <div className="px-[10px]">
            <h1 className=" text-[30px] lg:text-[40px] lg:pt-10 xl:pt-20 text-black font-bold pb-2 text-center md:text-left ">
              About Us
            </h1>
            <p className=" text-[15px] text-justify text-black ">
              The Special Marshals are arm of the Federal Road Safety Corps.
              This arm was created by the same FRSC statute Sec. 10 (1)… “the
              Corps” which shall consist of such number of uniform and
              non-uniform members as may be determined, from time to time, by
              the Commission. FRSC Establishment Act (2007). They are men and
              women of means, with proven integrity in society, and able to
              influence their immediate environment (work place/community) in
              favor of the course of road safety. The Act empowers the Special
              Marshals to carry out patrol and other activities that ensure good
              road usage on our highways. Just like their Regular counterparts,
              they can arrest and book traffic offenders as well as prosecute
              them when necessary.
            </p>
          </div>
        </div>
      </div>
      <div className=" container mx-auto">
        <div className=" px-2 md:px-0 grid gap-6 grid-cols-1 lg:grid-cols-5">
          <div className=" px-[10px] md:px-0 col-span-2">
            <div className=" flex items-center">
              <div className=" text-justify">
                <p>
                  <h1 className=" text-[22px] leading-tight lg:text-[28px] font-bold mb-2 text-black text-center lg:text-right">
                    Strength of Special Marshals
                  </h1>
                  The strength of Special Marshals currently stands at 13,053 in
                  all the 12 Zones. In 1996, over 15,000 members of Special
                  Marshals were registered nationwide, shortly after then the
                  management deemed it necessary to prune down the number to a
                  manageable figure of 8000. In 2001 there was pressure from the
                  National Executive Committee of the Special Marshals for
                  increase. The Management approval increase the numbers of
                  members to its current figure of 13, 053. Management has
                  approved the increase of more members to 30,000 by 2012. With
                  this approval, membership of Special Marshals is to be spread
                  across the country to the grassroots and to include all
                  profession.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className=" hidden items-center justify-center md:flex justify-self-center gap-6 w-[100px] mt-[-170px]">
            <img
              src={bullsEye}
              className=" hidden mt-20 xl:mt-10 lg:block"
              alt=""
            />
            <img
              src={bullsEye}
              className=" hidden mt-20 xl:mt-10 lg:block "
              alt=""
            />
          </div>

          <div className=" col-span-2">
            <div className="flex items-center justify px-[10px]">
              <div>
                <h1 className="text-[24px] lg:text-[28px] mb-2 font-bold text-black text-center lg:text-left  ">
                  Functions
                </h1>
                <ul className="list-none lg:list-disc">
                  <li>
                    Monitor road users and Road Marshals with a view to
                    providing constructive feedback to the Commission
                  </li>
                  <li>
                    Patrol the highways and control traffic on group basis
                  </li>
                  <li>
                    Participate in research activities relevant to road safety
                  </li>
                  <li>
                    Organize, sponsor or participate in workshops, public
                    enlightenment programmers and Road Safety Campaigns
                  </li>
                  <li>Organize and encourage Road Safety Clubs</li>
                  <li>
                    Any other function as may be assigned from time to time by
                    the Corps Marshal and Chief Executive
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className=" grid justify-items-center lg:grid-cols-5 mt-10">
          <div className=" px-[10px] lg:px-0 col-span-2">
            <div className=" flex items-center">
              <div className=" text-justify">
                <h1 className=" text-[24px] lg:text-[28px] font-bold mb-2 text-black text-center lg:text-right">
                  Achievements
                </h1>
                <p className="px-[10px]">
                  The activities of the Special Marshals have brought the
                  awareness of road safety to most segments of the society
                  because their membership cuts across every sphere of our
                  society. Academicians, transporters, civil society groups,
                  journalists, captains of industry, celebrities etc are all
                  involved in Special Marshals activities. By their numbers, the
                  Special Marshals have adequately complemented the efforts of
                  the Regular Marshals, especially in the areas of patrol
                  operations, traffic control, public enlightenment etc. The
                  Special Marshals have also made tangible contributions to the
                  operation of the Corps through donation of vehicles and other
                  patrol equipment, as well as sponsorship of public
                  enlightenment programmes of the Corps. The Special Marshals
                  serve as image makers for the Corps through advocacy.
                </p>
              </div>
            </div>
          </div>

          <div className=" hidden items-center justify-center md:flex justify-self-center gap-6 w-[100px] mt-[-256px]">
            <img
              src={bullsEye}
              className=" hidden mt-20 xl:mt-10 lg:block"
              alt=""
            />
            <img
              src={bullsEye}
              className=" hidden mt-20 xl:mt-10 lg:block "
              alt=""
            />
          </div>
          <div className="col-span-2 mt-4 lg:mt-0">
            <div className="flex items-center px-[10px] lg:px-0">
              <div>
                <h1 className="text-[24px] lg:text-[28px] mb-2 font-bold text-black text-center lg:text-left">
                  Marshals Kit
                </h1>
                <p className="text-justify px-[10px]">
                  All Special Marshals must be kitted with the prescribed
                  regalia and identity cards. The regalia is blue with
                  reflective ape/material by the side and round the middle. It
                  has a face cap with an arm band. The arm band and uniform
                  carry the personal identification number of each Special
                  Marshal. In the event of any Special Marshal leaving the
                  outfit, he/she must surrender his kits and ID card to the
                  Commanding Officer. If the Kit or ID card is lost or stolen,
                  the Special Marshal has to make out an affidavit and apply to
                  RSHQ for a replacement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
