import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import logo from "../../img/frsc-logo.webp";

const links = [
  {
    href: "https://www.frsc.gov.ng",
    text: "FRSC HQ.",
    target: "_blank",
    className: "underline",
  },
  { href: "/#root", text: "Home" },
  { href: "/#about-us", text: "About Us" },
  { href: "/#location", text: "Location" },
];

function BasicExample() {
  const { auth, adminRoles } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="relative">
      <nav className="bg-white shadow-lg p-6 lg:p-4 fixed top-0 w-full z-50">
        <div className="container mx-auto flex justify-between items-center h-16">
          <div>
            <a href="/" className="flex-shrink-0">
              <img
                alt=""
                src={logo}
                style={{ height: "80px", marginLeft: "50px" }}
                className="hidden lg:block w-300"
              />
            </a>
          </div>

          {/* Hamburger menu for smaller screens */}
          <img
            alt=""
            src={logo}
            style={{
              height: "80px",
              marginRight: "100%",
              position: "absolute",
            }}
            className=" lg:hidden lg:block w-300"
          />
          <div
            className="lg:hidden ml-4"
            style={{
              backgroundColor: "rgb(59 130 246)",
              borderRadius: "5px",
            }}
          >
            {" "}
            {/* Add ml-4 for left margin */}
            <button
              className="text-black px-4 py-2 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="rgb(167 199 251)"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          {/* Navigation links for larger screens */}
          <div
            className={`hidden lg:flex space-x-3 ${
              mobileMenuOpen ? "hidden" : "flex"
            }`}
          >
            {links.map((link, index) => (
              <NavItem key={index} href={link.href} target={link.target}>
                {link.text}
              </NavItem>
            ))}
          </div>

          {/* Login and Look up Marshal buttons */}
          <div className="hidden lg:flex space-x-4">
            {auth?.token ? (
              <NavItem href="dashboard">Dashboard</NavItem>
            ) : (
              <a
                href="login"
                className="text-black flex items-center justify-center px-4 py-2 lg:py-0 hover:text-blue-800"
                style={{
                  backgroundColor: "rgb(13, 110, 253)",
                  height: "40px",
                  width: "120px",
                  color: "white",
                }}
              >
                Login
              </a>
            )}
            <a
              href="lookup"
              className="text-black flex items-center justify-center px-4 py-2 lg:py-0 hover:text-blue-800"
              style={{
                borderColor: "rgb(13, 110, 253)",
                borderWidth: "2px",
                height: "40px",
                width: "180px",
                color: "rgba(30, 64, 175, 1)",
              }}
            >
              Look up Marshal
            </a>
          </div>
        </div>
      </nav>

      {/* Responsive navigation menu for smaller screens */}
      {/* Responsive navigation menu for smaller screens */}
      {mobileMenuOpen && (
        <div
          className="lg:hidden fixed top-16 w-full bg-white z-50"
          style={{ marginTop: "20px" }}
        >
          <div className="flex flex-col space-y-2 p-4">
            {links.map((link, index) => (
              <NavItem
                key={index}
                href={link.href}
                target={link.target}
                onClick={toggleMobileMenu}
              >
                {link.text}
              </NavItem>
            ))}
            {auth?.token ? (
              <NavItem href="dashboard" onClick={toggleMobileMenu}>
                Dashboard
              </NavItem>
            ) : (
              <NavItem href="login" onClick={toggleMobileMenu}>
                <a
                  href="login"
                  className="text-black font-bold flex items-center justify-start px-4 py-2 lg:py-0 hover:text-blue-800"
                  style={{
                    backgroundColor: "rgb(13, 110, 253)",
                    height: "40px",
                    width: "100%",
                    color: "white",
                  }}
                >
                  Login
                </a>
              </NavItem>
            )}
            <NavItem href="lookup" onClick={toggleMobileMenu}>
              <a
                href="lookup"
                className="text-black flex items-center justify-start px-4 py-2 lg:py-0 hover:text-blue-800"
                style={{
                  borderColor: "rgb(13, 110, 253)",
                  borderWidth: "2px",
                  height: "40px",
                  width: "100%",
                  color: "#1e40af",
                  marginBottom: "30px",
                }}
              >
                Look up Marshal
              </a>
            </NavItem>
          </div>
        </div>
      )}

      {/* Placeholder to reserve space for the fixed Navbar */}
      <div className="h-16"></div>

      {/* Rest of your page content goes here */}
    </div>
  );
}

const NavItem = ({ href, target, children, onClick }) => (
  <a
    href={href}
    target={target}
    className="text-black px-4 py-2 lg:py-0 cursor-pointer flex justify-center items-center"
    onClick={onClick}
  >
    {children}
  </a>
);

export default BasicExample;
