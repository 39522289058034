import React, { useEffect, useLayoutEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import SimpleUpload from "../FileUploads/SimpleUpload";
import imageBg from "../../img/image.png";
import { TfiTrash } from "react-icons/tfi";
import useAuth from "../../hooks/useAuth";
import { addMarshal } from "../../utils/marshals/addMarshal";
import { toast } from "react-toastify";
import { addUnits } from "../../utils/units/addUnit";
import { updateUnit } from "../../utils/units/editUnit";
import { getUnits } from "../../utils/units/getUnits";
import Loader from "../common/Loader";

function AddUnit({ closeModal, mode, rowData }) {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    location: "",
    id: rowData?.Id,
  });
  const [images, setImages] = useState([]);
  // const [marshalDetail, setMarshalDetail] = useState(null);
  const { units, setUnits, getRoles } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddUnit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (mode === "edit") {
      await updateUnit(formData, rowData?.Id)
        .then((res) => {
          setLoading(false);
          toast.success("Unit updated successfully");
          closeModal(false);
          getUnits().then((res) => {
            setUnits(res?.data?.data);
          });
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error adding unit:", err);
          toast.error(`${err.response.data.message}`);
        });
    } else {
      await addUnits(formData)
        .then((res) => {
          setLoading(false);
          toast.success("Unit added successfully");
          getUnits().then((res) => {
            setUnits(res?.data?.data);
          });
          closeModal(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error adding unit:", err);
          toast.error(`${err.response.data.message}`);
        });
    }
  };

  useLayoutEffect(() => {
    getRoles();
    setFormData({
      name: rowData?.name || "",
      number: rowData?.number || "",
      location: rowData?.location || "",
      id: rowData?.id || "",
    });
  }, [rowData]);

  return (
    <div className="p-8 !h-[200px] !text-black">
      <div className="flex justify-between items-center mb-4 text-[18px] text-black">
        <h3 className="font-semibold ">
          {mode === "edit" ? "Edit" : mode === "view" ? "View" : "Add"} Unit
        </h3>
        <FaTimes
          onClick={() =>
            mode
              ? closeModal({ modalName: "", showModal: false })
              : closeModal(false)
          }
          className="cursor-pointer"
        />
      </div>
      <form className="  gap-[16px]" onSubmit={handleAddUnit}>
        <div className=" mb-5">
          <label className="font-semibold text-[16px] mb-[8px]">
            Unit Name
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className=" mb-5">
          <label className="font-semibold text-[16px] mb-[8px]">
            Unit Number
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="number"
            value={formData.number}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="">
          <label className="font-semibold text-[16px] mb-[8px]">
            Unit Location
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>

        {mode !== "view" && (
          <button className=" col-span-2 w-full mt-5 rounded-[8px] bg-[#0D6EFD] text-white text-[16px] py-[16px]">
            {loading ? <Loader /> : "Submit"}
          </button>
        )}
      </form>
    </div>
  );
}

export default AddUnit;
