import apiClient from "../../api/axios";

export const deleteOffence = async (id) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  return await apiClient({
    url: `/api/v1/Offences/delete`,
    method: "delete",
    data: {id},
    headers: {
      Authorization: "Bearer " + token,
      "content-type": "application/json",
    },
  });
};
