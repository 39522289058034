import React, { useEffect, useLayoutEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import SimpleUpload from "../FileUploads/SimpleUpload";
import imageBg from "../../img/image.png";
import { TfiTrash } from "react-icons/tfi";
import useAuth from "../../hooks/useAuth";
import { addMarshal } from "../../utils/marshals/addMarshal";
import { toast } from "react-toastify";
import { addUnits } from "../../utils/units/addUnit";
import { updateUnit } from "../../utils/units/editUnit";
import { getUnits } from "../../utils/units/getUnits";
import Loader from "../common/Loader";
import { updateOffence } from "../../utils/offences/updateOffence";
import { getAllOffences } from "../../utils/offences/getAllOffence";
import { addOffence } from "../../utils/offences/addOffence";
import { getAllCategory } from "../../utils/category/getAllCategory";

function AddOffence({ setData, closeModal, mode, rowData }) {
  const [formData, setFormData] = useState({
    id: rowData?.Id,
    Infrigement: "",
    Code: [],
    Points: [],
    Penalty: [],
    CategoryId: [],
    // id: rowData?.Id,
  });
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState([]);
  const { getRoles } = useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // setloading(true)
    getAllCategory()
      .then((res) => {
        // setloading(false)
        setCategory(res?.data?.data);
      })
      .catch(() => {
        console.log("error fetching offences");
        // setloading(false);
      });
    getRoles();
  }, []);
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddUnit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (mode === "edit") {
      await updateOffence(formData, rowData?.Id)
        .then((res) => {
          setLoading(false);
          toast.success("Offence updated successfully");
          closeModal(false);
          getAllOffences().then((res) => {
            setData(res?.data?.data);
          });
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error adding offence:", err);
          toast.error(`${err.response.data.message}`);
        });
    } else {
      await addOffence(formData)
        .then((res) => {
          setLoading(false);
          toast.success("offence added successfully");
          getAllOffences().then((res) => {
            setData(res?.data?.data);
          });
          closeModal(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error adding offence:", err);
          toast.error(`${err.response.data.message}`);
        });
    }
  };

  useLayoutEffect(() => {
    getRoles();
    setFormData({
      Id: rowData?.categoryId,
      Infrigement: rowData?.infrigement,
      Code: rowData?.code || '',
      Points: rowData?.points || '',
      Penalty: rowData?.penalty || '',
      CategoryId: rowData?.id || '',
      // id: rowData?.Id || "",
    });
  }, [rowData]);


  console.log(category);
  
  console.log(rowData);

  return (
    <div className="p-8 !h-[200px] !text-black">
      <div className="flex justify-between items-center mb-4 text-[18px] text-black">
        <h3 className="font-semibold ">
          {mode === "edit" ? "Edit" : mode === "view" ? "View" : "Add"} Offence
        </h3>
        <FaTimes
          onClick={() =>
            mode
              ? closeModal({ modalName: "", showModal: false })
              : closeModal(false)
          }
          className="cursor-pointer"
        />
      </div>
      <form className="  gap-[16px]" onSubmit={handleAddUnit}>
          <div className=" grid grid-cols-2 gap-5">
            <div className=" mb-5">
                <label className="font-semibold text-[16px] mb-[8px]">
                Infrigement
                </label>
                <input
                className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
                name="Infrigement"
                value={formData.Infrigement}
                onChange={handleChange}
                required
                readOnly={mode === "view"}
                />
            </div>

            <div className=" mb-5">
              <label className="font-semibold text-[16px] mb-[8px]">
                Code
              </label>
              <input
                className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
                name="Code"
                value={formData.Code}
                onChange={handleChange}
                required
                readOnly={mode === "view"}
              />
            </div>
          </div>
        <div className="">
          <label className="font-semibold text-[16px] mb-[8px]">
            Points
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="Points"
            value={formData.Points}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>

        <div className=" mt-5">
        <label className="font-semibold text-[16px] mb-[8px]">
          Penalty
        </label>
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="Penalty"
          value={formData.Penalty}
          onChange={handleChange}
          required
          readOnly={mode === "view"}
        />
      </div>

      <div className=" mt-5">
      <label className="font-semibold text-[16px] mb-[8px]">
        Category
      </label>
      {mode === "view" ? (
        <input
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="categoryId"
          value={category?.find((c) => c.id == rowData?.categoryId)?.name}
          onChange={handleChange}
          required
          readOnly={mode === "view"}
        />
      ) : (
        <select
          className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
          name="CategoryId"
          value={formData.CategoryId}
          onChange={handleChange}
          required
          readOnly={mode === "view"}
        >
          <option value="" disabled selected>
            Select a category
          </option>
          {category?.map((category) => (
            <option key={category?.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      )}
    </div>

        {mode !== "view" && (
          <button className=" col-span-2 w-full mt-5 rounded-[8px] bg-[#0D6EFD] text-white text-[16px] py-[16px]">
            {loading ? <Loader /> : "Submit"}
          </button>
        )}
      </form>
    </div>
  );
}

export default AddOffence;
