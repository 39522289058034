import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";

function Tabs({ setFilteredDocs, activeTab, setActiveTab, viewMode, UserId }) {
  const {
    myDocuments,
    setMyDocuments,
    getMyDocuments,
    docType,
    setDocType,
    getSMDocuments,
  } = useAuth();
  const changeTab = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "All") {
      if (viewMode) {
        getSMDocuments(UserId);
      } else {
        getMyDocuments();
      }
    } else {
      const newDocuments = [...myDocuments];
      const filteredDocuments = newDocuments.filter(
        (doc) => doc.DocumentType === tabName
      );
      const newDocType = [...docType];
      const findIndex = newDocType.findIndex((doc) => doc.Name === tabName);
      docType[findIndex].count = filteredDocuments?.length;
      setDocType(newDocType);
      setFilteredDocs(filteredDocuments);
    }
  };
  return (
    <div className="flex flex-wrap justify-center lg:justify-start items-center gap-[8px]">
      <p
        className={`${
          activeTab === "All" ? "bg-[#0D6EFD] text-white" : "bg-white"
        } w-fit p-1 px-3 rounded-[8px] cursor-pointer`}
        onClick={() => changeTab("All")}
      >
        All
      </p>
      {docType?.map((type) => {
        return (
          <p
            key={type?.Id}
            className={`
                ${
                  activeTab === type?.name
                    ? "bg-[#0D6EFD] text-white"
                    : "bg-white"
                } w-fit p-1 px-3 rounded-[8px] cursor-pointer
              `}
            onClick={() => changeTab(type?.name)}
          >
            {type?.name}
          </p>
        );
      })}
    </div>
  );
}

export default Tabs;
