import React, { useEffect } from "react";
import Table from "./ReportsTable";
import useAuth from "../../hooks/useAuth";

function ManageMarshallsReports() {
  const { getAllReports } = useAuth();

  useEffect(() => {
      getAllReports();
    }, []);
  return (
    <>
      <div className="report-header mainContent-header">
        <h2 className="text-3xl">All Reports</h2>
      </div>
      <div className="mainContent-EditProfile--btn">
        <>
          <Table />
        </>
      </div>
    </>
  );
}

export default ManageMarshallsReports;
