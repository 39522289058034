import React from "react";
import { Link } from "react-router-dom";

function index() {
  return (
    <div>
      <h3>Sorry nothing here</h3>
      <p>
        <Link to="/">Go back to homepage</Link>
      </p>
    </div>
  );
}

export default index;
