import React, { useEffect } from "react";
import ManualUploads from "../components/ManualUpload";

function ManualsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <ManualUploads />;
}

export default ManualsPage;
