import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { MdOutlineFileUpload } from 'react-icons/md';

function DragAndDropUpload({handleUploadFunction}) {
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the dropped files
        console.log(acceptedFiles);
        handleUploadFunction(acceptedFiles)
      }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <div {...getRootProps()} className='border flex flex-col items-center justify-center py-5 border-dashed border-gray-300 px-2 rounded-md h-[200px] cursor-pointer'>
            <input {...getInputProps()} />
            <i className=' mb-2 text-[20px]'><MdOutlineFileUpload /></i>
            <p className=' text-black text-[10px] font-bold'>Drag and Drop <span className=' text-gray-400'>or choose file</span>  To Upload</p>
        </div>
    )
}

export default DragAndDropUpload