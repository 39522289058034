import React, { useEffect } from "react";
import { useState } from "react";
// import CreateMarshal from "./CreateMarshal";
// import configData from "../../config.json";

import Modal from "react-modal";
import useAuth from "../../hooks/useAuth";
// import axios from "axios";
import Table from "../common/Table";
import { HiOutlineSearch } from "react-icons/hi";
import ModalContainer from "../common/ModalContainer";
// import AddNewReport from "../Forms.js/AddNewReport";
// import AddMarshal from "../Forms.js/AddMarshal";
// import { getAllMarshals } from "../../utils/marshals/getAllMarshal";
import ConfirmationModal from "../common/ConfirmationModal";
// import { deleteReport } from "../../utils/reports/deleteReport";
import { DeleteUnit } from "../../utils/units/deleteUnit";
// import { addUnits } from "../../utils/units/addUnit";
import AddUnit from "../Forms.js/addUnit";
import { toast } from "react-toastify";
import { getUnits } from "../../utils/units/getUnits";

function ManageUnits() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getRoles, units, setUnits, filterMarshalByUnitId } = useAuth();
  
  useEffect(() => {
    setLoading(true)
    getUnits()
      .then((res) => {
        setLoading(false)
        setUnits(res?.data?.data);
      })
      .catch(() => {
        console.log("error fetching marshals");
      });
    getRoles();
  }, [modalIsOpen]);
  
  // const [selectedUnit, setSelectedUnit] = useState("");
  const [openModal, setOpenModal] = useState({
    modalName: "",
    showModal: false,
  });
  const [rowData, setRowData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // const filterMarshalByUnit = (unit) => {
  //   setSelectedUnit(unit);
  //   if (unit !== "") {
  //     filterMarshalByUnitId(unit);
  //   } else {
  //     getAllMarshals();
  //   }
  // };

  const handleUnitDelete = () => {
    setLoading(true);
    DeleteUnit(rowData?.id)
      .then((res) => {
        setLoading(false);
        setOpenModal({ modalName: "", showModal: false });
        toast.success("Unit deleted successfully");
        getUnits()
          .then((res) => {
            setUnits(res?.data?.data);
        })
          .catch(() => {
            console.log("error fetching Units");
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("error deleting unit", err);
        setLoading(false);
      });
  };

  
  return (
    <div className="min-h-[92vh]">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-[30px]">
        <div className="relative flex gap-[21px] items-center">
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
        <button
          className="rounded-[8px] bg-[#0D6EFD] text-[16px] text-white px-3 py-[10px] flex items-center gap-2 mt-4 lg:mt-0"
          onClick={() => setModalIsOpen(true)}
        >
          Add a Unit <span className="text-[28px] font-semibold">+</span>
        </button>
      </div>
      <Table
      state={loading}
        header={[
          { name: "Unit Name", identifier: "name" },
          { name: "Unit Number", identifier: "number" },
          { name: "Unit Location", identifier: "location" },
        ]}
        data={units}
        options={{
          variant: "primary",
          tableStyle: "",
          allowSorting: true,
          allowActions: true,
          actions: {
            edit: true,
            view: true,
            delete: true,
          },
          showPagination: true,
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        searchQuery={searchQuery}
        
      />
      {modalIsOpen && (
        <ModalContainer>
          <AddUnit closeModal={setModalIsOpen} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "edit" && (
        <ModalContainer>
          <AddUnit mode="edit" closeModal={setOpenModal} rowData={rowData} />
        </ModalContainer>
      )}

      {openModal.showModal && openModal.modalName === "view" && (
        <ModalContainer>
          <AddUnit mode="view" closeModal={setOpenModal} rowData={rowData} />
        </ModalContainer>
      )}
      {openModal.showModal && openModal.modalName === "delete" && (
        <ModalContainer height="auto">
          <ConfirmationModal
            message="Are you sure you want to delete this Unit?"
            handleCancel={() =>
              setOpenModal({ modalName: "", showModal: false })
            }
            handleConfirm={handleUnitDelete}
            loading={loading}
          />
        </ModalContainer>
      )}
    </div>
  );
}

export default ManageUnits;
