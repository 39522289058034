import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import "./confirm.css";

function ConfirmBox({ setShowDelBox, dataId }) {
  console.log(dataId);
  const { auth, ApiUrl, myManuals, setMyManuals, myDocuments, setMyDocuments } =
    useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteDocument = async () => {
    setIsDeleting(true);
    await axios({
      url: ApiUrl + `/api/v1/Documents/delete`,
      method: "delete",
      data: { id: dataId },
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        setIsDeleting(false);
        toast.success(`${res.data.message}`);
        const newManuals = myManuals.filter(
          (doc) => doc.id !== res.data.data.id
        );
        setMyManuals(newManuals);
        const newDocuments = myDocuments.filter(
          (doc) => doc.Id !== res.data.data.id
        );
        setMyDocuments(newDocuments);
        setShowDelBox(false);
      })
      .catch((err) => {
        setIsDeleting(false);
        console.log(err);
      });
  };
  return (
    <div className="del-box__container">
      <h4>Are you sure you want to delete?</h4>
      <div className="del-cta__container">
        <button className="yes text-bg-success" onClick={deleteDocument}>
          {isDeleting ? "Please wait..." : "Yes"}
        </button>
        <button
          className="no text-bg-danger"
          onClick={() => setShowDelBox(false)}
        >
          No
        </button>
      </div>
    </div>
  );
}

export default ConfirmBox;
