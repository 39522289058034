import { toast } from "react-toastify";
import apiClient from "../../api/axios";

const checkFileSpecification = (
  file,
  requiredSize,
  allowedFormats,
  docType,
  userId
) => {
  console.log("params", {
    file,
    requiredSize,
    allowedFormats,
    docType,
    userId,
  });
  const fileSize = file?.size;
  const fileFormat = file.type?.split("/")?.[1];
  const sizeInMB = fileSize / (1024 * 1024);
  if (sizeInMB > requiredSize) {
    console.log(`File size should be less than ${requiredSize}MB`);
    return toast.error(`File size should be less than ${requiredSize}MB`);
  } else if (!allowedFormats?.includes(fileFormat)) {
    console.log('fileFormat', fileFormat);
    console.log(
      `File format not supported, Allowed formats are ${allowedFormats?.toString()})`
    );
    return toast.error(
      `File format not supported, Allowed formats are ${allowedFormats?.toString()}`
    );
  } else {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("DocumentType", docType);
    formData.append("UserId", userId);
    console.log("formData", formData);
    return formData;
  }
};

export const uploadFile = async (
  file,
  requiredSize,
  allowedFormats,
  docType,
  userId
) => {
  const user = JSON.parse(window.localStorage.getItem("auth"));
  const token = user?.token;
  const formData = await checkFileSpecification(
    file,
    requiredSize,
    allowedFormats,
    docType,
    userId
  );
  console.log("formData", formData);
  if (formData) {
    return await apiClient.post(`/api/v1/Documents/uploadFile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    });
  }
};
