import React from "react";
import { BsCloudUpload } from "react-icons/bs";

function SimpleUpload({ handleUploadFunction }) {
  return (
    <label className="w-full h-[119px] flex justify-center items-center border !border-dashed rounded-[8px] cursor-pointer">
      <div className="flex justify-center flex-col items-center gap-2">
        <BsCloudUpload size={20} />
        <p>Upload an image</p>
      </div>
      <input
        type="file"
        name=""
        id=""
        className="hidden"
        onChange={handleUploadFunction}
        accept="image/*"
      />
    </label>
  );
}

export default SimpleUpload;
