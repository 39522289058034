import React from 'react';
import bg1 from '../../img/contact-bg1.png';
import bg2 from '../../img/contact-bg2.svg';

const ContactAlt = () => {
  const backgroundStyle = {
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover', 
  };
  const backgroundStyleAlt = {
    backgroundImage: `url(${bg2})`,
    backgroundSize: 'cover', 
  };
  return (
    <div>
      <div style={backgroundStyle} className="h-[50vh]">    
        <div style={backgroundStyleAlt} className=' w-full h-full flex items-center justify-center text-center'>
         <div className=' px-3 md:px-0'>
            <h1 className=' font-bold text-[24px] lg:text-[32px] text-white pb-3'>How to Join The Special Marshals</h1>
            <p className=' text-[15px] text-white pb-3 mb-4'>To inquire about the Special Marshal Unit, kindly visit the nearest Federal Road Safety Corps (FRSC) Command or Click The BUTTON <br /> BELOW. Thank you.</p>
            <button className=' px-8 bg-white text-black font-medium py-2  text-[15px]'>Enlist Now</button>
         </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAlt;
