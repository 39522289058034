import React, { useEffect, useMemo, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import Tabs from "./Tabs";
import { getDocumentTypes } from "../../utils/documents/getDocumentTypes";
import useAuth from "../../hooks/useAuth";
import ModalContainer from "../common/ModalContainer";
import AddDocument from "../Forms.js/AddDocument";
import { getMyDocuments } from "../../utils/documents/getMyDocuments";
import DocumentCardView from "./DocumentCardView";
import pdfImage from "../../img/pdf.png";
import docImage from "../../img/doc.png";
import xlsImage from "../../img/xls.png";
import defaultImage from "../../img/image.png";
import Loader from "../common/Loader";

function MyDocuments() {
  const [activeTab, setActiveTab] = useState("All");
  const [filteredDocs, setFilteredDocs] = useState([])
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [loading, setLoading] = useState(true)
  const { setDocType, myDocuments, setMyDocuments } = useAuth()
  useMemo(() => {
    getDocumentTypes().then((res) => {
      setDocType(res?.data)
    })
    getMyDocuments().then((res) => {
      setLoading(false)
      setMyDocuments(res?.data?.data)
    })
  }, [])
  return <div className="min-h-[92vh]">
    <div className=" relative flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-[37px]">
      <div className="relative">
        <input type="text" placeholder="search" className="relative w-[327px] h-[45px] px-4 pl-8 rounded-[8px] border border-[#D9D9D9] outline-none" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <HiOutlineSearch className="absolute top-[13px] left-[10px]" size={20} />
      </div>
      <button className="bg-[#0D6EFD] text-white p-2 rounded-[8px] px-4 flex items-center gap-2 mt-4 lg:mt-0" onClick={() => setOpenUploadModal(true)}>Add New Document <span className="text-[18px]">+</span></button>
    </div>
    <div className="mb-[28px]">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} setFilteredDocs={setFilteredDocs} />
    </div>
    {loading && <div className="w-[924px] flex justify-center items-center h-[200px] bg-white bg-opacity-40">
      <Loader color="black" />
    </div>}
    <div className="w-full lg:w-[924px] grid grid-cols-2 lg:grid-cols-6 gap-[8px] gap-y-[16px] bg-white bg-opacity-40 p-[8px]">
      {!loading && myDocuments?.length > 0 ? myDocuments?.filter((d) => searchTerm !== "" ? d?.actualFileName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) : d)?.filter((docs) => activeTab !== "All" ? docs?.DocumentType === activeTab : docs)?.map((doc) => (
        <DocumentCardView key={doc.id} docId={doc?.id} docImage={doc?.fileName.includes("pdf")
          ? pdfImage
          : doc?.fileName.includes("doc")
            ? docImage
            : doc?.fileName.includes("xls")
              ? xlsImage
              : defaultImage}
          docName={doc?.actualFileName}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          docFileName={doc?.fileName}
          imagePath={doc?.path}
          showDots={true}
        />
      )) : !loading && <p>No Document available</p>}
    </div>
    {openUploadModal &&
      <ModalContainer>
        <AddDocument closeModal={setOpenUploadModal} />
      </ModalContainer>
    }
  </div>;
}

export default MyDocuments;
