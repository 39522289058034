import axios from "axios";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { AiOutlinePlus } from "react-icons/ai";
import { MdClose, MdEdit, MdFileDownload } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import "./style.css";
import pdfImage from "../../img/pdf.png";
import docImage from "../../img/doc.png";
import xlsImage from "../../img/xls.png";
import ConfirmBox from "../confrimationBox";
import AWS from "aws-sdk";

function ManualUploads() {
  const {
    ApiUrl,
    auth,
    myManuals,
    getMyManuals,
    loadingManuals,
    REACT_APP_AWS_KEY,
    REACT_APP_AWS_SECRET_KEY,
    REACT_APP_AWS_BUCKET_NAME,
  } = useAuth();
  const fileTypes = [
    "JPG",
    "PNG",
    "GIF",
    "PDF",
    "JPEG",
    "DOC",
    "DOCX",
    "XLS",
    "XLSX",
  ];
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [docId, setDocId] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [showDelBox, setShowDelBox] = useState(false);
  AWS.config.update({
    accessKeyId: REACT_APP_AWS_KEY,
    secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  });
  function downloadImageOnClick(url) {
    var s3 = new AWS.S3();
    s3.getObject(
      { Bucket: REACT_APP_AWS_BUCKET_NAME, Key: url.FileName },
      function (error, data) {
        if (error != null) {
          console.log("Failed to retrieve an object: " + error);
        } else {
          let blob = new Blob([data.Body]);
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = url.FileName;

          // Trigger the download by simulating a click on the <a> element
          downloadLink.click();
          // Remove link from body
          document.body.removeChild(downloadLink);
        }
      }
    );
  }
  const displayDocuments = () => {
    if (myManuals?.length > 0) {
      return (
        <div className="gallery manual">
          {myManuals?.map((img, index) => {
            return (
              <>
                <div key={img.Id} className="pictures">
                  <img
                    src={
                      img?.fileName.includes("pdf")
                        ? pdfImage
                        : img?.fileName.includes("doc")
                        ? docImage
                        : img?.fileName.includes("xls")
                        ? xlsImage
                        : img?.path
                    }
                    alt=""
                    style={{ width: "100%" }}
                  />
                  <div className="overlay-bg">
                    <div className="div" style={{ color: "white" }}>
                      <p>{img?.ActualFileName}</p>
                      <div className="flex">
                        <a
                          href={img?.path}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "white" }}
                        >
                          View Manual
                        </a>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <BiTrash
                            size={20}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowDelBox(true);
                              setDocId(img.id);
                            }}
                          />
                          <MdFileDownload
                            color="white"
                            size={20}
                            onClick={() => downloadImageOnClick(img)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      );
    } else {
      return <p>No Document Found</p>;
    }
  };

  useEffect(() => {
    getMyManuals();
  }, []);

  // docType.forEach((doc, index) => {
  //   doc.count = 0;
  // });
  // const [documentDetails, setDocumentDetails] = useState({});

  const getImg = (imgSrc) => {
    setImgSrc(imgSrc);
    setShowViewModal(true);
  };

  // const deleteDocument = async (id) => {
  //   await axios({
  //     url: ApiUrl + `/api/v1/Documents/delete`,
  //     method: "delete",
  //     data: { id },
  //     headers: {
  //       "Content-Type": "application/json-patch+json",
  //       Authorization: "Bearer " + auth.token,
  //     },
  //   })
  //     .then((res) => {
  //       toast.success(`${res.data.message}`);
  //       const newDocuments = myManuals.filter(
  //         (doc) => doc.Id !== res.data.data.Id
  //       );
  //       setMyManuals(newDocuments);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const updateDocument = async (file) => {
  //   console.log(file);
  //   const { Id } = documentDetails;
  //   const formData = new FormData();
  //   formData.append("File", file);
  //   formData.append("DocumentType", documentType);
  //   formData.append("StaffId", auth?.userDetails?.SpecialMarshalId);
  //   formData.append("SMDocumentId", Id);
  //   await axios({
  //     url: ApiUrl + `/api/v1/Documents/updateDocument`,
  //     method: "put",
  //     data: formData,
  //     headers: {
  //       "Content-Type": "application/json-patch+json",
  //       Authorization: "Bearer " + auth.token,
  //     },
  //   })
  //     .then((res) => {
  //       toast.success(`${res.data.message}`);
  //       setShowAddModal(false);
  //       getMyManuals();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const [sfile, setFile] = useState(null);
  const handleChange = (file) => {
    // debugger;
    setFile(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(ApiUrl + `/api/v1/Documents/addManual`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("File uploaded successfully");
        setShowAddModal(false);
        getMyManuals();
        setFile(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="mainContent-header">
      <h2 className="text-3xl">Manuals</h2>
      <div className="document-create-btn">
        <button
          className="btn btn-primary flex items-center gap-2"
          onClick={() => setShowAddModal(true)}
        >
          <AiOutlinePlus />
          Upload New Manual
        </button>
      </div>
      {showDelBox && (
        <ConfirmBox setShowDelBox={setShowDelBox} dataId={docId} />
      )}
      <div className={showAddModal ? "addModal open" : "addModal"}>
        <div className="close-btn">
          <MdClose
            size={30}
            onClick={() => {
              setShowAddModal(false);
            }}
          />
        </div>
        <h3 className="modalHeader">Add Manuals</h3>
        <p>Attach Document</p>
        <div className="backdrop-zone">
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            className="file-upload"
            // multiple={false}
          />
        </div>
      </div>
      <div className={showViewModal ? "viewModal open" : "viewModal"}>
        <div className="close-btn">
          <MdClose
            color="white"
            size={30}
            onClick={() => setShowViewModal(false)}
          />
        </div>
        <img src={imgSrc} alt="" />
      </div>
      <div className="mydocument-images__container">
        {loadingManuals ? <p>Loading</p> : <>{displayDocuments()}</>}
      </div>
    </div>
  );
}

export default ManualUploads;
