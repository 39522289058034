import React, { useEffect } from "react";
import Documents from "../components/Documents/MyDocuments";

function DocumentsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Documents />;
}

export default DocumentsPage;
