import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import logo from "../../img/logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPwd() {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { ApiUrl } = useAuth();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email").replace(/\s/g, "+");
  let code = searchParams.get("code");
  code = encodeURIComponent(code);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        ApiUrl +
          `/api/v1/Account/resetPasswordByUser
        `,
        {
          email,
          password,
          code,
        }
      );
      if (res.status === 200) {
        setLoading(false);
        toast.success(`${res.data.message}`);
        setMsg("Redirecting back to login...");
        setInterval(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.errors) {
        toast.error(`${error.response.data.errors.Password}`);
      } else {
        toast.error(`${error.response.data.message}`);
      }
    }
  };
  return (
    <div className="form-wrapper">
      <div className="login form-container">
        <div className="form-content">
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
          </Link>
          {msg && (
            <p
              style={{
                background: "green",
                padding: 0.5 + "rem",
                color: "white",
                marginBottom: 1 + "rem",
              }}
            >
              {msg}
            </p>
          )}
          {errMsg && (
            <p
              style={{
                background: "red",
                padding: 0.5 + "rem",
                color: "white",
                marginBottom: 1 + "rem",
              }}
            >
              {errMsg}
            </p>
          )}
          <form className="login-inputFields">
            <input
              placeholder="Email"
              type="email"
              name="email"
              value={email}
              disabled
            />
            <input
              placeholder="Type your new password"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </form>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Reset Password"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPwd;
