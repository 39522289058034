import { FiHome } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsPerson } from "react-icons/bs";
import { HiOutlineDocumentChartBar } from "react-icons/hi2";
import { BsFolder2 } from "react-icons/bs";
import { RiFolderOpenLine } from "react-icons/ri";
import { BiBuildingHouse } from "react-icons/bi";
import { MdMenuBook } from "react-icons/md";
import { BsPersonRaisedHand } from "react-icons/bs";
import { TbBrandBooking } from "react-icons/tb";
import { TbCategoryPlus } from "react-icons/tb";

export const AdminSidebarLinks = [
  {
    name: "Dashboard",
    link: "",
    icon: <FiHome />,
  },
  {
    name: "My Reports",
    link: "/reports",
    icon: <IoDocumentTextOutline />,
  },
  {
    name: "Manage Marshal",
    link: "/all-marshalls",
    icon: <BsPerson />,
  },
  {
    name: "Manage Report",
    link: "/manage-reports",
    icon: <HiOutlineDocumentChartBar />,
  },
  {
    name: "My Documents",
    link: "/my-documents",
    icon: <BsFolder2 />,
  },
  {
    name: "Marshal Documents",
    link: "/marshal-documents",
    icon: <RiFolderOpenLine />,
  },
  {
    name: "Units",
    link: "/manage-units",
    icon: <BiBuildingHouse />,
  },
  {
    name: "Manuals",
    link: "/manuals",
    icon: <MdMenuBook />,
  },
  {
    name: "Offence",
    link: "/offences",
    icon: <BsPersonRaisedHand />,
  },
  {
    name: "Category",
    link: "/category",
    icon: <TbCategoryPlus />,
  },
  {
    name: "Bookings",
    link: "/bookings",
    icon: <TbBrandBooking />,
  },
];
export const UsersSidebarLinks = [
  {
    name: "Dashboard",
    link: "",
    icon: <FiHome />,
  },
  {
    name: "My Reports",
    link: "/reports",
    icon: <IoDocumentTextOutline />,
  },
  {
    name: "My Documents",
    link: "/my-documents",
    icon: <BsFolder2 />,
  },
  {
    name: "My Bookings",
    link: "/admin9",
    icon: <TbBrandBooking />,
  },
];

export const UnitCoordinatorLinks = [
  {
    name: "Dashboard",
    link: "",
    icon: <FiHome />,
  },
  {
    name: "My Reports",
    link: "/reports",
    icon: <IoDocumentTextOutline />,
  },
  {
    name: "Manage Marshal",
    link: "/all-marshalls",
    icon: <BsPerson />,
  },
  {
    name: "My Documents",
    link: "/my-documents",
    icon: <BsFolder2 />,
  },
  {
    name: "Marshal Documents",
    link: "/marshal-documents",
    icon: <RiFolderOpenLine />,
  },
  {
    name: "My Bookings",
    link: "/admin9",
    icon: <TbBrandBooking />,
  },
];
