import React from "react";
import mainBg from '../../img/mainbg.png';
import strokeLeft from '../../img/stroke-right.png';
import strokeRight from '../../img/stroke-left.png';
import firstCar from '../../img/car-driving.png';
import license from '../../img/license.png';
import record from '../../img/record.png';
import work from '../../img/work.png';

function Index() {
  const backgroundStyle = {
    backgroundImage: `url(${mainBg})`,
    backgroundSize: 'cover',
  };

  const leftStrokeStyle = {
    backgroundImage: `url(${strokeLeft})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };

  const rightStrokeStyle = {
    backgroundImage: `url(${strokeRight})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <section className="bg-white mb-0">
      <div className='flex justify-end mb-2'>
        <div className='bg-primary py-1 mt-2 md:w-[30vw]'></div>
      </div>
      <div style={backgroundStyle}>
        <div className="container py-10 md:py-20 mx-auto">
          <h1 className="font-bold text-[24px] lg:text-[48px] text-black text-center mb-9 ">Qualifications</h1>
      
          <p className="text-[15px] text-black text-center mt-8">
            To qualify as a Special Marshal, it is imperative to successfully meet the criteria outlined in the checklist below.
          </p>
        </div>
        <div className="container mx-auto grid justify-items-center grid-cols-1 md:grid-cols-2 pb-20">
          <div className="">
            <img src={strokeLeft} className="w-[250px] xl:w-[300px] h-[500px] xl:h-[600px]" alt="" />
            <div className="ml-5 xl:ml-10 mt-[-470px] xl:mt-[-560px]">
              <div className="mb-3 w-[300px] xl:w-[450px] flex items-center justify-center flex-col xl:h-[350px] lg:w-[340px] bg-white p-2 text-center">
                <img src={firstCar} alt="car" />
                <p>Own A Personal Serviceable Car</p>
              </div>
              <div className="mb-3 w-[300px] xl:w-[450px] flex items-center justify-center flex-col xl:h-[350px] lg:w-[340px] bg-white p-2 text-center">
                <img src={work} alt="car" />
                <p>Means of Livelihood</p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="z-10 relative  ml-5 mt-4">
            <div className="mb-3 w-[300px] xl:w-[450px] flex items-center justify-center flex-col xl:h-[350px] lg:w-[340px] bg-white p-2 text-center">
                <img src={record} alt="car" />
                <p>Zero Criminal Record</p>
              </div>
              <div className="mb-3 w-[300px] xl:w-[450px] flex items-center justify-center flex-col xl:h-[350px] lg:w-[340px] bg-white p-2 text-center">
                <img src={license} alt="car" />
                <p>Licensed Driver</p>
              </div>
            </div>
            <img src={strokeRight} className=" hidden md:block ml-36 md:ml-48 xl:ml-52 mt-[-440px] xl:mt-[-540px] w-[200px] xl:w-[300px] h-[450px] xl:h-[560px]" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
