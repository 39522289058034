import React from 'react'
import logo from "../../img/frsc-logo.webp"
import Location from "../Location";

function index() {
  return (
        <footer id="footer" className=" ">
        <div className="container mx-auto">
        <div className=' mt-4 flex justify-center md:justify-start'>
            <img className=" h-[100px]" src={logo} alt="Special Marshal"/>
         </div>
            <div className=" px-3 lg:px-0 grid md:grid-cols-1 gap-10 md:gap-10 lg:grid-cols-4 pt-4 mb-5 lg:mb-20">

                <div className=" lg:col-span-2 fadeInUp animated" data-wow-duration="500ms">
               
                    <div className="">  
                        <h6 className=' text-blue-500 font-bold text-[25px] pb-2 text-left'>Special Marshals</h6>
                        <p className="text-[15px] text-black font-medium md:max-w-[500px] leading-[26px] text-justify lg:text-left">This arm was created by the same FRSC statute Sec. 10 (1)… “the Corps” which shall consist of such number of uniform and non-uniform members as may be determined, from time to time, by the Commission. FRSC Establishment Act (2007).</p>
                    </div>
                </div>
              
                <div className="fadeInUp animated" data-wow-duration="500ms"
                data-wow-delay="900ms">
                        <div className="">
                            <h6 className=' text-blue-500 font-bold text-[25px] pb-2'>Support</h6>
                            <ul>
                                <li><a href="#" className=' text-black text-[26px font-semibold leading-10]'>FRSC HQ</a></li>
                                <li><a href="#" className=' text-black text-[26px font-semibold leading-10]'>Market Blog</a></li>
                                <li><a href="#" className=' text-black text-[26px font-semibold leading-10]'>Help Center</a></li>
                                <li><a href="#" className=' text-black text-[26px font-semibold leading-10]'>Pressroom</a></li>
                            </ul>
                        </div>
                    </div>

                
                     <div className="  fadeInUp animated" data-wow-duration="500ms"
                        data-wow-delay="600ms">
                        <h6 className=' text-blue-500 font-bold text-[20px]  sm:mt-2 lg:mt-0 pb-2 lg:text-left'>Address</h6>
                        <div className="text-[16px] font-medium leading-[26px] ">
                           <p className=' text-black md:text-left'>2 Akinsanya St, Ojodu, Ojodu <br /> Berger 101233, Lagos</p>
                        </div>
                    </div>

    

            </div>
           
            <div>
                <Location />
            </div>

            <div className="row">
                <div className="col-md-12">
                    <p className="text-black text-center mb-10">
                        Copyright © 2022 FRSC SM Lagos. All rights reserved.
                        Developed by <a href="http://processrus.com/" className=' text-black' target="_blank">Process R Us</a>
                    </p>
                </div>
            </div>
        </div>

        
    </footer>
  )
}

export default index