import React, { useEffect, useState } from "react";
// import userImage from "../../img/user.webp";
import { FiEdit } from "react-icons/fi";
import { getMarshalById } from "../../utils/marshals/getMarshalById";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import { getUnits } from "../../utils/units/getUnits";

const ProfileHome = () => {
  const [details, setDetails] = useState(null);
  const { auth, setAuth, setUnits } = useAuth();
  useEffect(() => {
    getMarshalById(auth?.userDetails?.id).then((res) => {
      console.log("Data", res?.data?.data);
      setDetails(res?.data?.data);
      
    });
    getUnits().then((res)=>{
      setUnits(res?.data?.data)
    })
  }, []);
  console.log(auth);
  return (
    <div className=" text-[9px] md:text-[13px] flex gap-10">
      <div className=" w-[180px] hidden lg:block border-r border-slate-300">
        <button className=" text-white font-bold px-5 py-1 rounded-lg bg-blue-600">
          My Profile
        </button>
      </div>

      <div className=" w-full">
        <Section1 det={auth} State2={setDetails} State1={details}/>

        <Section2 det={auth} State2={setDetails} State1={details}/>

        <div className=" border w-full border-slate-300 rounded-lg mt-5 px-5 py-5">
          <h1 className=" font-bold mb-3">Address</h1>
          <div className=" grid grid-cols-1 md:grid-cols-2">
            <div className=" w-[200px]">
              <h1 className=" font-medium">Area of Residence/ Address</h1>
              <h2 className=" font-medium text-slate-500">
                {details?.address}
              </h2>
            </div>

            <div>
              <h1 className=" font-bold">Local Government Area</h1>
              <p className=" font-medium text-slate-500">Nil</p>
            </div>
          </div>
        </div>

        <Section3 det={auth} State2={setDetails} State1={details}/>
      </div>
    </div>
  );
};

export default ProfileHome;
