import React, { useEffect, useLayoutEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import SimpleUpload from "../FileUploads/SimpleUpload";
import imageBg from "../../img/image.png";
import { TfiTrash } from "react-icons/tfi";
import useAuth from "../../hooks/useAuth";
import { addMarshal } from "../../utils/marshals/addMarshal";
import { toast } from "react-toastify";
import { updateMarshal } from "../../utils/marshals/updateMarshal";
import { getAllMarshals } from "../../utils/marshals/getAllMarshal";
import Loader from "../common/Loader";
// import { getMarshalById } from "../../utils/marshals/getMarshalById";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddMarshal({ closeModal, mode, rowData }) {
  const [formData, setFormData] = useState({
    firstname: "", 
    lastname: "",
    emailAddress: "",
    profession: "",
    number: [],
    sex: "",
    userName: "",
    userType: "",
    password: "",
    address: "",
    unitId: "",
    smNumber: "",
    doB: "",
  });

  
  const [dobs, setDOB] = useState();
  const [images, setImages] = useState([]);
  const { units, userRoles, getRoles, marshall, auth, filterMarshalByUnitId, userDetails, setMarshall } = useAuth();
  const [loading, setLoading] = useState(false);
  const unitId = auth?.userDetails?.unitId;



  // console.log(rowData?.Unit?.Name);
  // useEffect(() => {
  //   getMarshalById(auth?.userDetails?.SpecialMarshalId).then((res) => {
  //     console.log("Data", res?.data?.data);
  //     setDetails(res?.data?.data);
      
  //   });
  //   getUnits().then((res)=>{
  //     setUnits(res?.data?.data)
  //   })
  // }, []);

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleChange = (e) => {
    const value = e.target.name === 'unitId' ? parseInt(e.target.value) : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const convertSizeToKB = (size) => {
    return Math.round(size / 1024);
  };

  const uploadImageToDatabase = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    const response = await fetch("https://example.com/upload", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    return data;
  };

  const handleUploadFunction = async (e) => {
    const files = e.target.files[0];
    setImages([
      ...images,
      { name: files.name, size: convertSizeToKB(files.size) },
    ]);
    console.log("files", files);
  };

  const removeImageFromArray = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };
  

  const handleAddMarshal = async (e) => {
    e.preventDefault();
    setLoading(true);
    await addMarshal(formData)
      .then((r) => {
        console.log("res");
        closeModal(false);
        setLoading(false)
        toast.success(`${r?.data?.message}`)
        if (auth?.roles === "UNITCOORDINATOR"){
          filterMarshalByUnitId(unitId)
        }else{

          getAllMarshals().then((res) => {
            setMarshall(res?.data?.data)
          })
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false)
        toast.error(`${err.response.data.message}`);
      });
  };
  const handleEditMarshal = async (e) => {
    e.preventDefault();
    setLoading(true);
    await updateMarshal(formData)
      .then((r) => {
        console.log("res");
        closeModal(false);
        toast.success(`${r?.data?.message}`)
        setLoading(false)
        getAllMarshals().then((res) => {
          setMarshall(res?.data?.data)
        })
      })
      .catch((err) => {
        console.log("err");
        toast.error(`${err.response.data.message}`);
      });
  };


 
  
  useLayoutEffect(() => {
    getRoles();
    setFormData({
      id: rowData?.id,
      firstname: rowData?.firstName,
      lastname: rowData?.lastName,
      emailAddress: rowData?.emailAddress,
      profession: rowData?.profession,
      phoneNumber: rowData?.phoneNumber,
      unitId: rowData?.UnitId,
      sex: rowData?.sex,
      address: rowData?.address,
      userName: rowData?.userName,
      userType: rowData?.userRole,
      smNumber: rowData?.smNumber,
      doB: dobs,
    });
  }, []);

 




  
 

  console.log(rowData);


  const filteredUnit = units?.filter(item => item?.id == rowData?.unitId);
// console.log(filteredUnit.length > 0 ? filteredUnit[0].name : "Unit Not Found");


  return (
    <div className="p-8 !text-black">
      <div className="flex justify-between items-center mb-4 text-[18px] text-black">
        <h3 className="font-semibold ">
          {mode === "edit" ? "Edit" : mode === "view" ? "View" : "Add"} Marshal
        </h3>
        <FaTimes
          onClick={() =>
            mode
              ? closeModal({ modalName: "", showModal: false })
              : closeModal(false)
          }
          className="cursor-pointer"
        />
      </div>
      <form className="grid lg:grid-cols-2 gap-[16px]" onSubmit={mode === "edit" ? handleEditMarshal : handleAddMarshal}>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            First Name
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            Last Name
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">Email</label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">SM Number</label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="smNumber"
            value={formData.smNumber}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            Occupation
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="profession"
            value={formData.profession}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            Area of Residence/ address
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className=" flex flex-col">
          <label className="font-semibold text-[16px] mb-[8px]">
            Date of birth
          </label>
          {
            mode === "view" ? (
              <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="doB"
            value={rowData?.doB}
            onChange={handleChange}
          />
            ) : (
              <DatePicker
  selected={dobs}
  onChange={(date) => {
    setDOB(date); // Update the dobs state
    setFormData({ ...formData, doB: date.toISOString() }); // Update the formData state with the selected date
  }}
  peekNextMonth
  showMonthDropdown
  showYearDropdown
  dropdownMode="select"
  className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
  placeholderText="Select Date of Birth"
  dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSS'Z'" // Format for date-time
  maxDate={new Date()} // Optionally set maxDate to prevent selecting future dates
/>

            )
          }
         
         
        </div> 
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            Choose a Unit
          </label>
          {mode === "view" ? (
            <input
              className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
              name="unitId"
              value={filteredUnit.length > 0 ? filteredUnit[0].name : "Unit Not Found"}
              onChange={handleChange}
              required
              // arr.filter(item => item === value)
              readOnly={mode === "view"}
            />
          ) : (
            <select
              className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
              name="unitId"
              value={formData.unitId}
              onChange={handleChange}
              // required
              readOnly={mode === "view"}
            >
              <option value="" disabled selected>
                Select Unit
              </option>
              {units?.map((unit) => (
                <option key={unit?.id} value={unit?.id}>
                  {unit?.name}
                </option>
              ))}
            </select>
          )}
        </div>
      
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            Mobile Number
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="number"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">Gender</label>
          {mode === "view" ? (
            <input
              className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              // required
              readOnly={mode === "view"}
            />
          ) : (
            <select
              className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              // required
            >
              <option value="" disabled selected>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          )}
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">Username</label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <label className="font-semibold text-[16px] mb-[8px]">
            User Type
          </label>
          {mode === "view" ? (
            <input
              className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
              name="userRole"
              value={formData.userType}
              onChange={handleChange}
              required
              readOnly={mode === "view"}
            />
          ) : (
            <select
              className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              required
            >
              <option value="" selected disabled>
                Select User Type
              </option>
              {userRoles?.map((role) => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
          )}
        </div>
        {mode !== "view" && mode !== "edit" && (
          <>
            <div className="col-span-2 lg:col-span-1">
              <label className="font-semibold text-[16px] mb-[8px]">
                Password
              </label>
              <input
                className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="font-semibold text-[16px] mb-[8px]">
                Confirm Password
              </label>
              <input
                className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}
        {mode !== "view" && (
          <button className=" col-span-2 w-full rounded-[8px] bg-[#0D6EFD] text-white text-[16px] py-[16px]">
            {loading ? <Loader /> : "Submit"}
          </button>
        )}
      </form>
    </div>
  );
}

export default AddMarshal;
