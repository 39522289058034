import React from "react";
import LookUp from "../components/LookUp";
import Navbar from "../components/Navbar";


function LookupPage() {
  return (
    <div>
        <Navbar/>
       <LookUp />
    </div>
  );
}

export default LookupPage;
