import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import SimpleUpload from "../FileUploads/SimpleUpload";
import imageBg from "../../img/image.png";
import { TfiTrash } from "react-icons/tfi";
import { addReport } from "../../utils/reports/addReport";
import Loader from "../common/Loader";
import { uploadFile } from "../../utils/documents/uploadFile";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { updateReport } from "../../utils/reports/updateReport";
import { getMyReports } from "../../utils/reports/getMyReport";

function AddNewReport({ closeModal, mode, rowData }) {
  const [formData, setFormData] = useState({
    title: "",
    noOfSMPresent: "",
    incident: "",
    location: "",
    documentId: "",
    description: "",
  });
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth, myReports, setMyReports } = useAuth();

  useEffect(() => {
    setFormData({
      id: rowData?.id,
      title: rowData?.title,
      noOfSMPresent: rowData?.noOfSMPresent,
      incident: rowData?.incident,
      location: rowData?.location,
      documentId: rowData?.documents[0]?.id || 0,
      description: rowData?.description,
    });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Validation for noOfSMPresent, incident, location
    if (["title","noOfSMPresent"].includes(name)) {
      // Allow only alphanumeric characters and hyphen
      const validInput = value.replace(/[^a-zA-Z0-9-]/g, '');
      setFormData({
        ...formData,
        [name]: validInput,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  

  const convertSizeToKB = (size) => {
    return Math.round(size / 1024);
  };

  const handleUploadFunction = async (e) => {

    
    const files = e.target.files[0];
    console.log("files", files);
    setLoading(true);
    toast
      .promise(
        uploadFile(
          files,
          5,
          ["jpg", "jpeg", "png"],
          "Others",
          auth?.userDetails?.UserId
            ? auth?.userDetails?.UserId
            : auth?.userDetails?.id
        ),
        {
          pending: "Uploading file to database, Please wait...",
          success: "File Uploaded Successfully",
          error: "Failed to Upload File",
        }
      )
      .then((res) => {
        console.log("res", res?.data?.data);
        setLoading(false);
        setImages([
          ...images,
          { name: files?.name, size: convertSizeToKB(files?.size) },
        ]);
        setFormData({ ...formData, documentId: res?.data?.data?.id });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeImageFromArray = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  const handleAddNewReport = (e) => {
    e.preventDefault();
    if (images.length === 0) {
      toast.error("Please upload at least one image.");
      return;
    }
    
    if (formData.noOfSMPresent.length < 1 || formData.incident.length < 1 || formData.location.length < 1 || formData.description.length <1 || formData.title.length <1) {
      toast.error("Please enter at least 3 characters for all relevant fields.");
      return;
    }

    setLoading(true);
    addReport(formData)
      .then((res) => {
        console.log("res");
        setLoading(false);
        closeModal(false);
        toast.success("New report added succesfully")
        getMyReports().then((res) => {
          setMyReports(res?.data?.data)
        })
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleEditReport = (e) => {
    e.preventDefault();
    setLoading(true);
    updateReport(formData)
      .then((res) => {
        console.log("res");
        toast.success("Report updated succesfully")
        setLoading(false);
        closeModal(false);
        getMyReports().then((res) => {
          setMyReports(res?.data?.data)
        })
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="p-8 !text-black">
      <div className="flex justify-between items-center mb-4 text-[18px] text-black">
        <h3 className="font-semibold ">
          {" "}
          {mode === "edit" ? "Edit" : mode === "view" ? "View" : "Add New"}{" "}
          Report
        </h3>
        <FaTimes onClick={() => closeModal(false)} className="cursor-pointer" />
      </div>
      <form onSubmit={mode === "edit" ? handleEditReport : handleAddNewReport}>
        <div className="mb-[22px]">
          <label className="font-semibold text-[16px] mb-[8px]">Title</label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
            
          />
        </div>
        <div className="mb-[22px]">
          <label className="font-semibold text-[16px] mb-[8px]">
            Number of Special Marshal Present
          </label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="noOfSMPresent"
            value={formData.noOfSMPresent}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
           
          />
        </div>
        <div className="mb-[22px]">
          <label className="font-semibold text-[16px] mb-[8px]">Incident</label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="incident"
            value={formData.incident}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
           
          />
        </div>
        <div className="mb-[22px]">
          <label className="font-semibold text-[16px] mb-[8px]">Location</label>
          <input
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
     
          />
        </div>
        {mode !== "view" &&
          <div className="mb-[22px]">
            <label className="font-semibold text-[16px] mb-[8px]">
              Add Image
            </label>
            <SimpleUpload handleUploadFunction={handleUploadFunction} />
          </div>
        }
        {images?.length > 0 && (
          <div>
            {images.map((image, index) => (
              <div
                key={index}
                className="bg-[#2C81FF] bg-opacity-[7%] rounded-[8px] p-2 flex justify-between items-center mb-[22px]"
              >
                <div className="flex items-center gap-2">
                  <img src={imageBg} alt="" />
                  <span>
                    <p>{image.name}</p>
                    <p>{image.size} KB</p>
                  </span>
                </div>
                <span
                  className="cursor-pointer"
                  onClick={() => removeImageFromArray(index)}
                >
                  <TfiTrash size={20} />
                </span>
              </div>
            ))}
          </div>
        )}
        <div className="mb-[22px]">
          <label className="font-semibold text-[16px] mb-[8px]">
            Description
          </label>
          <textarea
            cols={5}
            className="w-full border p-2 py-3 rounded-[8px] outline-none boder-[#B7B7B7]"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            readOnly={mode === "view"}
            
          />
        </div>
        {mode !== "view" &&
          <button
            className="w-full rounded-[8px] bg-[#0D6EFD] text-white text-[16px] py-[16px]"
            disabled={loading}
          >
            {loading ? <Loader /> : "Submit"}
          </button>}
      </form>
    </div>
  );
}

export default AddNewReport;
